import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import useWindowSize from "../../../hooks/useWindowSize";
import "./OfferCard.css";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router-dom";

const OfferCard = ({
  details: {
    title,
    imageUrl,
    discountLabel,
    backgroundColor,
    redirectType,
    itemCode,
  },
}) => {
  const { width } = useWindowSize();
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };

  return (
    <div
      className="d-inline-flex flex-column justify-content-center align-items-around rounded OfferCard"
      style={{
        background: backgroundColor,
        padding: width > 768 ? "0.875rem" : "none",
        minWidth: width > 768 ? "200px" : "none",
      }}
      onClick={() =>
        width < 768 ? handleRedirect(redirectType, itemCode) : console.log("")
      }
    >
      <img
        src={imageUrl}
        className="OfferCard__productImage"
        alt="produt_photo"
        // style={{ height: "120px", width: "auto" }}
      />
      <div
        className={
          width > 425
            ? "d-flex flex-column align-items-center justify-content-center"
            : "d-flex flex-column align-items-start justify-content-center"
        }
      >
        <div
          className="mb-1 OfferCard__title"
          style={{ textAlign: width < 425 ? "left" : "center" }}
        >
          {title}
        </div>
        <div
          className="mb-2 OfferCard__offer"
          style={{ textAlign: width < 425 ? "left" : "center" }}
        >
          {discountLabel}
        </div>
      </div>
      {width > 768 && (
        <div
          className="d-flex add-cart-button text-center justify-content-around align-items-center false"
          style={{
            backgroundColor: "#2D9140",
            color: "white",
            fontWeight: "700",
            fontSize: "0.875rem",
            border: "1px solid transparent",
          }}
          onClick={() => handleRedirect(redirectType, itemCode)}
        >
          <div className="w-100 p-2">Shop Now</div>
        </div>
      )}
    </div>
  );
};

export default OfferCard;
