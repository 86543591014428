import React, { useState, useEffect, useContext } from "react";
import DeliverySlots from "../DeliverySlots/DeliverySlots";

import { getTimeSlots } from "../../api";
import moment from "moment";
import _ from "underscore";
import { AppContext } from "../../context/AppContext";

// NAVIGATION
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import "./Deliverybar.css";

SwiperCore.use([Navigation]);

const Deliverybar = props => {
  const { currentStore, timeSlots, setTimeSlots } = useContext(AppContext);

  const [slotData, setSlotData] = useState([]);
  const [day, setDay] = useState("Today");
  useEffect(() => {
    if (currentStore?.store_id) getSlots();
  }, [currentStore?.store_code]);

  const getSlots = () => {
    setDay("Today");
    getTimeSlots(
      currentStore?.store_code,
      moment().format("yyyy-MM-DD"),
      currentStore?.store_id
    )
      .then(async res => {
        const data = await slotDetailsFiller(
          res[9].default.time_limits,
          moment().format("yyyy-MM-DD"),
          "Today"
        );
        setSlotData(_.sortBy(data, "from"));
      })
      .catch(err => console.log(err.message))
      .finally(() => {
        getTomorrowSlots();
      });
  };

  const slotDetailsFiller = (slotData, date, day) => {
    let tempSlot = [];
    slotData?.map(i => {
      tempSlot.push({
        ...i,
        from: `${date}T${i?.from}:00`,
        to: `${date}T${i?.to}:00`,
        cut_off_time: `${date}T${i?.cut_off_time}:00`,
        start_time: `${date}T${i?.start_time}:00`,
        day: day
      });
    });
    return tempSlot;
  };

  const getTomorrowSlots = () => {
    getTimeSlots(
      currentStore?.store_code,
      moment().add(1, "days").format("yyyy-MM-DD"),
      currentStore?.store_id
    ).then(async res => {
      const data = await slotDetailsFiller(
        res[9].default.time_limits,
        moment().add(1, "days").format("yyyy-MM-DD"),
        "Tomorrow"
      );
      setSlotData(currentData => [...currentData, ..._.sortBy(data, "from")]);
    });
  };

  useEffect(() => {
    let timeFilter = slotData.filter(i =>
      moment(i.cut_off_time).isAfter(moment())
    );
    let slots = timeFilter.filter(
      x =>
        (x.slot_type === "express" && moment().isAfter(moment(x.start_time))) ||
        x.slot_type === "scheduled"
    );

    setTimeSlots(_.sortBy(slots, "from"));

    let slotInterval = setInterval(() => {
      let timeFilter = slotData.filter(i =>
        moment(i.cut_off_time).isAfter(moment())
      );
      let slots = timeFilter.filter(
        x =>
          (x.slot_type === "express" &&
            moment().isAfter(moment(x.start_time))) ||
          x.slot_type === "scheduled"
      );

      setTimeSlots(_.sortBy(slots, "from"));
    }, 1000);

    return () => clearInterval(slotInterval);
  }, [slotData]);

  return (
    <div
      className={
        "deliverybar height-anim" +
        (props.isShrinkHeader ? " header-height" : "")
      }
    >
      <div className="container d-flex justify-content-between align-items-center pr-0">
        <p className="deliverybar-label">Next Deliveries</p>
        <DeliverySlots
          fromTime={timeSlots[0]?.from}
          toTime={timeSlots[0]?.to}
          type={timeSlots[0]?.slot_type}
          deliverNow={true}
          day={day}
        />
        <div className="delivery-slots-wrap flex-grow-1 slide-arrows">
          <div className="slide-button-prev">
            <FiChevronLeft className="arrow" />
          </div>
          <div className="slide-button-next">
            <FiChevronRight className="arrow" />
          </div>
          <Swiper
            spaceBetween={10}
            slidesPerGroup={1}
            slidesPerView={"auto"}
            navigation={{
              nextEl: ".delivery-slots-wrap .slide-button-next",
              prevEl: ".delivery-slots-wrap .slide-button-prev"
            }}
            observer={true}
            observeParents={true}
          >
            {timeSlots.length > 0 ? (
              _.sortBy(timeSlots, "from")
                .slice(0, 5)
                .map((item, index) => {
                  return (
                    <SwiperSlide
                      key={"DeliverySlot" + moment().format("HHmm") + index}
                    >
                      <DeliverySlots
                        id={item.record_id}
                        index={index}
                        fromTime={item.from}
                        toTime={item.to}
                        type={item.slot_type}
                        day={item.day}
                      />
                    </SwiperSlide>
                  );
                })
            ) : (
              <SwiperSlide>
                <p className="fs-14 mt-3">No Slots Available</p>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Deliverybar;
