import React from "react";
import { Modal } from "react-bootstrap";
import "./ModalPopup.css";

function ModalPopup(props) {
  const handleClose = () => props.setShow(false);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleClose}
      animation={false}
      centered
    >
      <Modal.Body className="p-0">{props.children}</Modal.Body>
    </Modal>
  );
}

export default ModalPopup;
