import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ToastMessage from "./components/ToastMessage";
import LoginPopup from "./components/LoginPopup";
import { AppContext } from "./context/AppContext";
import Routes from "./routes/Routes";
import Location from "./pages/Location";
import MainLoader from "./components/MainLoader";
import {
  createCart,
  getCart,
  getDynamicHomePage,
  getMainCategories,
  getProductFromSku,
  getWishList,
  getInaamPoints,
  getAppDesignData
} from "./api";
import Breadcrumbs from "./components/Breadcrumbs";
import { useLocation } from "react-router";
import { FlagsContext } from "./context/FlagsProvider";
import SiteUnderMaintenance from "./pages/SiteUnderMaintenance";
import Cookies from "./components/Cookies";
import { BiArrowToTop } from "react-icons/bi";

const App = () => {
  const {
    setHomePageOrder,
    isLoggedIn,
    initializeFavourites,
    setShowToast,
    setToastData,
    updateCategory,
    currentStore,
    logout,
    initializeCart,
    cartUpdated,
    wishListUpdated,
    setInaamData,
    profile,
    setAppDesignData,
    store
  } = useContext(AppContext);
  const [homePageLoading, setHomePageLoading] = useState(false);
  const [megaLoading, setMegaLoading] = useState(false);

  useEffect(() => {
    const loadDesignData = async () => {
      const appDesignData = await getAppDesignData(store.sap_website_id);
      setAppDesignData({ ...appDesignData });
    };
    loadDesignData()
  }, []);

  const getMegaMenu = () => {
    setMegaLoading(true);
    getMainCategories(currentStore?.store_id)
      .then(res => {
        updateCategory(res[0]);
      })
      .catch(err => console.log(err))
      .finally(() => setMegaLoading(false));
  };

  const getWishlistData = () => {
    getWishList(currentStore?.store_code)
      .then(wishList => {
        let skuString = [];
        wishList?.map((item, index) => {
          skuString[index] = item?.product?.sku;
        });
        return skuString;
      })
      .then(resString => {
        getProductFromSku(currentStore?.store_code, resString)
          .then(res => {
            initializeFavourites(res);
          })
          .catch(e => {
            console.log(e.message);
          });
      })
      .catch(e => console.log(e));
  };

  const getCartData = () => {
    getCart(currentStore?.store_code)
      .then(res => {
        let skuString = [];
        res?.items?.map((item, index) => {
          skuString[index] = item?.sku;
        });
        getProductFromSku(currentStore?.store_code, skuString)
          .then(resTwo => {
            res?.items?.map((i, index) => {
              resTwo.map(j => {
                if (i.sku === j.sku) {
                  i.tax_included_price =
                    j.tax_included_special_price > 0
                      ? j.tax_included_special_price
                      : j.tax_included_price;
                  i.price =
                    j.tax_included_special_price > 0
                      ? j.special_price
                      : j.price;
                  i.salable_qty = j.salable_qty;
                  i.is_salable = j.is_salable;
                  i.image = j.image;
                  i.tax_percentage = j.tax_percentage;
                  i.is_salable = j.is_salable;
                  i.salable_qty = j.salable_qty;
                }
              });
            });
            initializeCart(res);
          })
          .catch(e => console.log(e.message));
      })

      .catch(() =>
        createCart(currentStore?.store_code)
          .then(cartres => {
            initializeCart({ id: cartres, items: [] });
          })
          .catch(e => {
            if (
              e.message ===
              "The consumer isn't authorized to access %resources."
            ) {
              setShowToast(true);
              setToastData({
                type: "info",
                text: "Session Expired. Please login Again"
              });
              logout();
            }
          })
      );
  };

  const getHomePageData = () => {
    setHomePageLoading(true);
    getDynamicHomePage(currentStore?.store_id)
      .then(res => setHomePageOrder(res?.data?.data))
      .catch(e => console.log(e))
      .finally(() => setHomePageLoading(false));
  };

  const getInaamData = () => {
    getInaamPoints(
      profile?.custom_attributes
        ?.filter(item => {
          return item?.attribute_code === "mobile_number";
        })[0]
        ?.value?.slice(0, 3) === "+91"
        ? profile?.custom_attributes
            ?.filter(item => {
              return item?.attribute_code === "mobile_number";
            })[0]
            ?.value.slice(3)
        : profile?.custom_attributes
            ?.filter(item => {
              return item?.attribute_code === "mobile_number";
            })[0]
            ?.value.slice(4)
    ).then(res => setInaamData(res));
  };

  useEffect(() => {
    profile?.custom_attributes?.filter(item => {
      return item?.attribute_code === "mobile_number";
    })[0]?.value && getInaamData();
  }, [isLoggedIn]);

  useEffect(() => {
    isLoggedIn && currentStore?.store_code && getCartData();
  }, [isLoggedIn, currentStore?.store_code, cartUpdated]);

  useEffect(() => {
    isLoggedIn && currentStore?.store_id && getWishlistData();
  }, [isLoggedIn, currentStore?.store_id, wishListUpdated]);

  useEffect(() => {
    if (currentStore?.store_id) {
      getHomePageData();
      getMegaMenu();
    }
  }, [currentStore?.store_id]);

  const {
    ECOMWEB_MAINTENANCE_MODE,
    ECOMWEB_MAINTENANCE_MODE_TITLE,
    ECOMWEB_MAINTENANCE_MODE_MESSAGE
  } = useContext(FlagsContext);
  return (
    <>
      {ECOMWEB_MAINTENANCE_MODE === "true" &&
      process.env.NODE_ENV === "production" ? (
        <SiteUnderMaintenance
          title={ECOMWEB_MAINTENANCE_MODE_TITLE}
          message={ECOMWEB_MAINTENANCE_MODE_MESSAGE}
        />
      ) : (
        <Nesto megaLoading={megaLoading} homePageLoading={homePageLoading} />
      )}
    </>
  );
};

const Nesto = ({ megaLoading, homePageLoading }) => {
  const location = useLocation();
  const { latLong } = useContext(AppContext);
  return (
    <>
      {!latLong?.location || location.pathname === "/select-location" ? (
        <Location />
      ) : (
        <MainWeb megaLoading={megaLoading} homePageLoading={homePageLoading} />
      )}
      <WebUtils />
    </>
  );
};

const MainWeb = ({ megaLoading, homePageLoading }) => {
  const location = useLocation();
  const { breadcrumbs } = useContext(AppContext);

  return (
    <>
      {!location.search && (megaLoading || homePageLoading) && (
        <MainLoader
          outerClassName="main-loader-container"
          innerClassName="main-loader-animation"
        />
      )}

      <Header />
      {location.pathname !== "/" && (
        <Breadcrumbs breadcrumb={breadcrumbs} className="container" />
      )}
      <Routes />
      <Footer />
    </>
  );
};

const WebUtils = () => {
  const {
    toastData,
    showLogin,
    setShowLogin,
    cookiesAccepted,
    updateCookiesAccepted
  } = useContext(AppContext);
  const location = useLocation();

  const [isScroll, setIsScroll] = useState(false);
  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 200) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    });
  }, []);

  return (
    <>
      <ToastMessage type={toastData?.type} text={toastData?.text} />
      <LoginPopup show={showLogin} onHide={setShowLogin} />
      <Cookies
        cookiesAccepted={cookiesAccepted}
        updateCookiesAccepted={updateCookiesAccepted}
      />
      {isScroll ? (
        <div
          className={
            "scroll-to-top d-flex justify-content-center align-items-center" +
            (location.pathname.match("/products") ? " pdp-scroll-to-top" : "")
          }
          onClick={scrollToTop}
        >
          <BiArrowToTop color="#eee" size={22} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
