import React, { useEffect, useState } from "react";
import Mainbar from "../Mainbar/Mainbar";
import Deliverybar from "../Deliverybar/Deliverybar";
import "./Header.css";

const Header = () => {
  const [isShrinkHeader, setIsShrinkHeader] = useState(false);
  function doSomething() {
    if (window.scrollY < 30) return setIsShrinkHeader(false);
    if (window.scrollY > 30) return setIsShrinkHeader(true);
  }
  useEffect(() => {
    let ticking = false;
    document.addEventListener("scroll", function (e) {
      if (!ticking) {
        window.requestAnimationFrame(function () {
          doSomething();
          ticking = false;
        });
        ticking = true;
      }
    });
  }, []);

  return (
    // <div className={"header-wrap" + (isShrinkHeader ? " small" : "")}>
    <div className="header-wrap">
      <div className="header-child-wrap">
        <Mainbar />
        <Deliverybar isShrinkHeader={isShrinkHeader} />
      </div>
    </div>
  );
};

export default Header;
