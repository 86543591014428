import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./BoughtBefore.css";
import Image from "./assets/image-here.jpg";
import HorizontalProductList from "../HorizontalProductList/HorizontalProductList";
import { AiOutlineHeart } from "react-icons/ai";

// NAVIGATION
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { useHistory } from "react-router";
import useWindowSize from "../../hooks/useWindowSize";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { IoIosArrowForward } from "react-icons/io";

const componentData = {
  title: "Items You Have Bought Before",
  products: [
    {
      title: "Olive Oil",
      image: Image,
      price: 1,
      amount: 1,
      unit: "L",
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
    {
      title: "Olive Oil",
      image: Image,
      unit: "L",
      price: 1,
      amount: 1,
      cta_link: "",
      cta_text: "Add To Cart",
    },
  ],
};

const BoughtBefore = ({ componentDesignData }) => {
  const { width } = useWindowSize();
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };

  return (
    <div className={`container bought-before-${componentDesignData?.position}`}>
      <div
        className="d-flex justify-content-between justify-content-md-start align-items-center"
        style={{ marginTop: "0.5rem" }}
      >
        <h1 className="homepage_title">
          {componentDesignData?.subTemplate?.details.title}
        </h1>
        <div
          style={{
            color: "#2D9140",
            marginLeft: "0.5rem",
            marginBottom: "12px",
            fontWeight: "700",
            fontSize: "0.875rem",
            cursor: "pointer",
          }}
          onClick={() =>
            handleRedirect(
              componentDesignData?.subTemplate?.details?.redirectType,
              componentDesignData?.subTemplate?.details?.itemCode
            )
          }
        >
          View all <IoIosArrowForward />
        </div>
      </div>
      {/* <div className="row bought-before"> */}
      <div className="swipe-arrows">
        <div className="swipe-button-prev">
          <FiChevronLeft className="swipe-arrow" />
        </div>
        <div className="swipe-button-next">
          <FiChevronRight className="swipe-arrow" />
        </div>
        <Swiper
          spaceBetween={10}
          freeMode={true}
          slidesPerView={
            width <= 768
              ? width > 555
                ? "3.5"
                : width > 425
                ? "3"
                : width <= 320
                ? "2"
                : "2.5"
              : width >= 1024
              ? "7"
              : "5"
          }
          navigation={{
            nextEl: `.bought-before-${componentDesignData?.position} .swipe-button-next`,
            prevEl: `.bought-before-${componentDesignData?.position} .swipe-button-prev`,
          }}
          style={{ marginLeft: "0", marginRight: "0" }}
          observer={true}
        >
          {componentData.products.map((item, index) => (
            <SwiperSlide key={"shop-by-" + index}>
              <div
                className="col d-flex flex-column bought-before-product align-items-between justify-content-center"
                style={{
                  position: "relative",
                  padding: "0.7rem",
                  paddingTop: "2rem",
                }}
              >
                <div
                  style={{ position: "absolute", top: "1rem", right: "1rem" }}
                >
                  <AiOutlineHeart size={25} />
                </div>
                <div>
                  <img
                    className="bought-before-banner-image"
                    src={item.image}
                    alt="product-imges"
                  />
                  <div className="d-flex flex-column align-items-start justify-content-center">
                    <div style={{ marginBottom: "0.25rem" }}>
                      <span className="bought-before-price">
                        AED {item.price}
                      </span>
                      <span style={{ fontSize: "1.125rem" }}> / </span>
                      <span className="bought-before-quantity">
                        {item.amount} L
                      </span>
                    </div>
                    <div className="bought-before-subtitle">
                      Price according to Amount
                    </div>
                    <div
                      style={{
                        color: "#181818",
                        fontSize: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {item.title}
                    </div>
                  </div>
                </div>

                <div className="d-flex add-cart-button text-center justify-content-around align-items-center false bought-before-add-to-cart">
                  <div class="w-100 p-2">Add to Cart</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BoughtBefore;

{
  /* <div className="container">
  <div
    className="container-fluid GridView_wrapper bought-brfore "
    style={{
      paddingBottom: "2rem",
      background: `linear-gradient(${componentDesignData?.subTemplate?.details.startGradient} 100%, ${componentDesignData?.subTemplate?.details.endGradient} 100%)`,
    }}
  >
    <div className="container">
      <div
        className="row py-2 d-flex align-items-center justify-content-between"
        style={{ width: "100%", margin: "auto" }}
      >
        <h2 className="homepage_title" style={{ marginBottom: "0" }}>
          {componentDesignData?.subTemplate?.details?.title}
        </h2>
        <div
          style={{
            background: "#52C41A",
            marginLeft: "1rem",
            borderRadius: "0.75rem",
          }}
          className="d-flex align-items-center justify-content-center p-2"
        >
          <AiOutlineClockCircle size={width <= 425 ? 15 : 20} />
          <div className="GridView__EndTime" style={{}}>
            {data.tagLine}
          </div>
        </div>
      </div>
      <div className="swipe-arrows">
        <div className="swipe-button-prev">
          <FiChevronLeft className="swipe-arrow" />
        </div>
        <div className="swipe-button-next">
          <FiChevronRight className="swipe-arrow" />
        </div>
        <Swiper
          spaceBetween={10}
          freeMode={true}
          slidesPerView={width < 768 ? "3.2" : "6.5"}
          navigation={{
            nextEl: ".bought-brfore .swipe-button-next",
            prevEl: ".bought-brfore .swipe-button-prev",
          }}
          observer={true}
        >
          {componentDesignData?.subTemplate?.widget.map((item, index) => (
            <SwiperSlide key={"bought-before-" + index}>
              <div
                className="col d-flex flex-column align-items-between justify-content-center"
                style={{ position: "relative", padding: "0.7rem" }}
              >
                <div
                  style={{ position: "absolute", top: "1rem", right: "1rem" }}
                >
                  <AiOutlineHeart size={25} />
                </div>
                <div>
                  <img
                    style={{
                      width: "168px",
                      height: "auto",
                      marginBottom: "1rem",
                    }}
                    src={item.image}
                    alt="product-imges"
                  />
                  <div className="d-flex flex-column align-items-start justify-content-center">
                    <div style={{ marginBottom: "0.25rem" }}>
                      <span
                        style={{ fontWeight: "bold", fontSize: "1.125rem" }}
                      >
                        AED {item.price}
                      </span>
                      <span style={{ fontSize: "1.125rem" }}> / </span>
                      <span style={{ fontSize: "0.9rem" }}>
                        {item.amount} L
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "0.75rem",
                        color: "#747474",
                        fontWeight: "400",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Price according to Amount
                    </div>
                    <div
                      style={{
                        color: "#181818",
                        fontSize: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {item.title}
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex add-cart-button text-center justify-content-around align-items-center false"
                  style={{
                    backgroundColor: "#2D9140",
                    color: "white",
                    fontSize: "12px",
                    border: "1px solid transparent",
                  }}
                >
                  <div class="w-100 p-2">Add to cart</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="grid-view-wrapper" style={{ magin: "auto" }}></div>
    </div>
  </div>
</div>; */
}
