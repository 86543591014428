import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";

const TwoBannerImageV1 = ({ widget }) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };
  return (
    <div
      className="d-flex align-items-center justify-content-between grid-wrapper-1"
      style={{
        background: `linear-gradient(${widget?.details?.startGradient} 100%, ${widget?.details?.endGradient} 100%)`,
      }}
    >
      <div className="offer-btn-wrapper d-flex flex-column align-items-start justify-content-around">
        <div className="offer-title">{widget?.details?.title}</div>
        <button
          className="banner-button"
          style={{
            background: widget?.details?.buttonColor,
            color: widget?.details?.buttonFontColor,
          }}
          onClick={() =>
            handleRedirect(
              widget?.details?.redirectType,
              widget?.details?.itemCode
            )
          }
        >
          {widget?.details?.buttonName}
        </button>
      </div>
      <img
        className="banner-images"
        src={widget?.details?.imageUrl}
        alt="fruits-and-vegetables"
      />
    </div>
  );
};

export default TwoBannerImageV1;
