import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";

const CircularGridItem = ({ widget, index }) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleWidgetRedirect = () => {
    let url = getRedirectionUrl(
      widget?.details?.redirectType,
      widget?.details?.itemCode
    );
    history.push(url);
  };
  return (
    <div
      onClick={handleWidgetRedirect}
      key={widget._id}
      className={`d-flex flex-column align-items-center justify-content-start grid-item ${
        index === 0 || index === 7 ? "Grid_cornerItems" : ""
      }`}
    >
      <div
        className="grid-image-wrapper outer_circle"
        style={{
          borderColor: `linear-gradient(${widget.details.borderStartGradient} 100%, ${widget.details.borderEndGradient} 100%)`,
          // width: "1rem",
          background: `linear-gradient(180deg, ${widget.details.startGradient} 0%, ${widget.details.endGradient} 100%)`,
          border: widget.details.borderStartGradient ? "" : "none",
        }}
      >
        <img
          className="circular-grid-image"
          src={widget.details.imageUrl}
          alt="jj"
        />
      </div>
      <div style={{ textAlign: "center" }} className="grid-title">
        {widget.details.title}
      </div>
    </div>
  );
};

export default CircularGridItem;
