import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

// ADDING BOOTSTRAP CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/css/Global.css";

// LOADER CSS
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AppContextProvider from "./context/AppContext";
import FlagsProvider from "./context/FlagsProvider";
// TagManager
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WJLWS8L",
  dataLayerName: "dataLayer"
};
process?.env?.REACT_APP_ENV === "production" &&
  TagManager.initialize(tagManagerArgs);

const defaultMaintenanceData = {
  ECOMWEB_MAINTENANCE_MODE: false,
  ECOMWEB_MAINTENANCE_MODE_MESSAGE:
    "We are currently down for maintenance. But don't worry, we will be back soon.",
  ECOMWEB_MAINTENANCE_MODE_TITLE: "Site under maintenance"
};
ReactDOM.render(
  <Router>
    <AppContextProvider>
      <FlagsProvider defaults={defaultMaintenanceData}>
        <App />
      </FlagsProvider>
    </AppContextProvider>
  </Router>,
  document.getElementById("root")
);
