import moment from "moment";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";
import Offer from "../widgets/Offer.png";
import OfferDesktop from "../widgets/Offer-Desktop.png";
import OfferMobile from "../widgets/Offer-phone.png";

const TwoBannerImageV2 = ({ widget }) => {
  const { width } = useWindowSize();
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };

  // const timeCalc = (endDate) => {
  //   var start = moment(new Date());
  //   var end = moment(endDate);
  //   return Math.round(end.diff(start, "days", true)) + " DAY OFFER";
  // };

  const timeCalc = () => {
    const difference = moment(widget?.details?.endDate).diff(moment());
    const duration = moment.duration(difference);
    const days = duration.asDays();
    return Math.floor(days);
  };

  return (
    <div
      className="d-flex align-items-center justify-content-between grid-wrapper-1-v2"
      style={{
        background: `linear-gradient(115.14deg, ${widget?.details?.startGradient} 0%, ${widget?.details?.endGradient} 100%)`,
        // width: "12rem",
        cursor: width >= 678 ? "auto" : "pointer",
        height: "100%",
        minWidth: width > 850 ? "364px" : "200px",
        position: "relative",
      }}
      onClick={() => {
        width <= 678
          ? handleRedirect(
              widget?.details?.redirectType,
              widget?.details?.itemCode
            )
          : console.log("");
      }}
    >
      {widget?.details?.endDate && (
        <div
          style={{
            position: "absolute",
            top: width > 425 ? "10px" : "5px",
            right: "0",
            width:
              width > 425
                ? widget < 952
                  ? "55%"
                  : width < 768
                  ? "55%"
                  : "50%"
                : "60%",
            overflow: "hidden",
          }}
        >
          <div className="two-banner-label-text">{timeCalc()} DAY OFFER</div>
          <img
            className="two-banner-label"
            src={width > 425 ? OfferDesktop : OfferMobile}
            alt="offer-end-date"
          />
        </div>
      )}

      <div className="offer-btn-wrapper d-flex flex-column align-items-start justify-content-between">
        <div className="banner-image-v2-discount-wrapper">
          {widget?.details?.discount}{" "}
          <span className="banner-image-v2-percent">%</span>{" "}
          <span className="banner-image-v2-off">Off</span>{" "}
        </div>
        <div className="banner-image-v2-title">{widget?.details?.title}</div>
        <div className="banner-image-v2-original-price">
          {widget?.details?.originalPrice}
        </div>
        <div className="banner-image-v2-discounted-price">
          {widget?.details?.discountPrice}
        </div>
        {width >= 768 && (
          <button
            className="banner-button banner-btn-v2"
            style={{
              background: widget?.details?.buttonColor,
              color: widget?.details?.buttonFontColor,
            }}
            onClick={() =>
              handleRedirect(
                widget?.details?.redirectType,
                widget?.details?.itemCode
              )
            }
          >
            Shop Now
          </button>
        )}
      </div>
      <img
        className="banner-v2-images"
        src={widget?.details?.imageUrl}
        alt="fruits-and-vegetables"
      />
    </div>
  );
};

export default TwoBannerImageV2;
