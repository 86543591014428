import "bootstrap/dist/css/bootstrap.css";
import React, { useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import "../../pages/HomepageNew/HomepageNew.css";
import "./Carousel.css";

const MainCarousel = ({ componentDesignData }) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();

  return (
    <div>
      <div className="carousel-wrapper">
        <Carousel style={{ margin: "auto" }}>
          {componentDesignData?.subTemplate?.widget?.map((widget) => (
            <Carousel.Item
              key={widget._id}
              interval={1500}
              onClick={() =>
                history.push(
                  getRedirectionUrl(
                    widget.details.redirectType,
                    widget.details.itemCode
                  )
                )
              }
            >
              <img
                className="d-block w-100"
                src={widget?.details?.imageUrlWeb || widget?.details?.imageUrl}
                alt="Image One"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default MainCarousel;
