import React, { useEffect, useContext, useState, useRef } from 'react';
import './Sidebar.css';
import { checkLocation } from '../../api';
import { AppContext } from '../../context/AppContext';
import SidebarCartItem from '../SidebarCartItem';
import { IoIosCloseCircle } from 'react-icons/io';
import { HiOutlineLocationMarker } from 'react-icons/hi';

import {
	IoCartOutline,
	IoSettingsOutline,
	IoLogOutOutline,
	IoStarOutline,
	IoLocationOutline,
	IoGlobeOutline,
	IoCloseCircleOutline,
} from 'react-icons/io5';
import { GrLocation } from 'react-icons/gr';
import { MdFavoriteBorder } from 'react-icons/md';
import { ImProfile } from 'react-icons/im';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import { BiBell, BiWallet } from 'react-icons/bi';
import { GoogleMap, Marker, Data } from '@react-google-maps/api';
import Geosuggest from 'react-geosuggest';
import Geocode from 'react-geocode';
import { useHistory } from 'react-router';
import { MAP_API_KEY } from '../../api/config';
import EmptyData from '../EmptyData';
import emptycart from '../../assets/images/empty-cart.svg';
import { Popover } from 'react-bootstrap';
import ConfirmPopUp from '../ConfirmPopUp';
import CategorySideMenu from '../CategorySideMenu';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import TagManager from 'react-gtm-module';
import { minimumPrice } from '../../constants/storeMinimum';

function Sidebar(props) {
	const [content, setContent] = useState();

	useEffect(() => {
		switch (props?.type) {
			case 'cart':
				setContent(
					<CartBar setSidebar={props?.setSidebar} sidebar={props?.sidebar} />
				);
				break;
			case 'map':
				setContent(<MapBar setSidebar={props?.setSidebar} />);
				break;
			case 'mobile-menu':
				setContent(
					<MobileMenu
						setSidebar={props?.setSidebar}
						setCartbar={props?.setCartbar}
						sidebar={props?.sidebar}
					/>
				);
				break;
		}
	}, []);

	return (
		<div className={props.sidebar ? 'sidebar active' : 'sidebar'}>
			<div
				className='sidebar-backdrop'
				onClick={() => props.setSidebar(false)}
			></div>
			<div
				className={
					props?.location === 'right'
						? 'sidebar-content-right'
						: 'sidebar-content-left'
				}
			>
				{content}
			</div>
		</div>
	);
}

function MobileMenu({ setSidebar, setCartbar, sidebar }) {
	const history = useHistory();
	const location = useLocation();
	let tempPath = location.pathname.split('/');
	const { logout, profile, latLong, store, cartData } = useContext(AppContext);
	const [showCat, setShowCat] = useState(false);
	const [showAccount, setShowAccount] = useState(false);
	const handleLogout = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='react-confirm-alert-body confirm-popup-button-body'>
						<h2 className='fw-700'>{'Logout?'}</h2>
						<div>{'Are you sure to log out?'}</div>
						<div className='btn-group d-flex justify-content-end'>
							<button
								className={'confirm-popup-button confirm-button-delete'}
								onClick={() => {
									setSidebar(false);
									logout();
									onClose();
								}}
							>
								{'Logout'}
							</button>
							<button
								className={'cancel-popup-button cancel-button-delete'}
								onClick={onClose}
							>
								No
							</button>
						</div>
					</div>
				);
			},
			closeOnClickOutside: true,
		});
	};
	return (
		<>
			{showCat && (
				<>
					<div className='d-flex align-items-center my-3'>
						<RiArrowLeftSLine size={30} onClick={() => setShowCat(false)} />
						<span className='ml-3 fs-18 fw-700'>{'All Categories'}</span>
					</div>
					<div>
						<CategorySideMenu
							catId={tempPath[tempPath.length - 1]}
							setSidebar={setSidebar}
						/>
					</div>
				</>
			)}
			{showAccount && (
				<>
					<div className='d-flex align-items-center my-3'>
						<RiArrowLeftSLine size={30} onClick={() => setShowAccount(false)} />
						<span className='ml-3 fs-18 fw-700'>{'Account'}</span>
					</div>
					<div>
						<div
							className='px-4 py-3 border-line'
							onClick={() => {
								history.push('/account/profile');
								setSidebar(false);
							}}
						>
							<ImProfile className='mr-2' />
							Profile
						</div>
						<div
							className='px-4 py-3 border-line'
							onClick={() => {
								history.push('/account/inaam');
								setSidebar(false);
							}}
						>
							<BiWallet className='mr-2' />
							INAAM Points
						</div>
						<div
							className='px-4 py-3 border-line'
							onClick={() => {
								history.push('/account/orders');
								setSidebar(false);
							}}
						>
							<IoCartOutline className='mr-2' />
							My Orders
						</div>
						<div
							className='px-4 py-3 border-line'
							onClick={() => {
								history.push('/account/address');
								setSidebar(false);
							}}
						>
							<IoLocationOutline className='mr-2' />
							Address Book
						</div>
						<div
							className='px-4 py-3 border-line'
							onClick={() => {
								history.push('/account/notification');
								setSidebar(false);
							}}
						>
							<BiBell className='mr-2' />
							Notifications
						</div>
						<div
							className='px-4 py-3 border-line'
							onClick={() => {
								history.push('/account/wishlist');
								setSidebar(false);
							}}
						>
							<MdFavoriteBorder className='mr-2' />
							My Wishlist
						</div>
						<div
							className='px-4 py-3 border-line'
							onClick={() => {
								history.push('/account/about');
								setSidebar(false);
							}}
						>
							<IoGlobeOutline className='mr-2' />
							About Nesto
						</div>
					</div>
				</>
			)}
			{!showCat && !showAccount && (
				<div className='mobile-menu-content h-100 d-flex flex-column justify-content-between'>
					<div>
						<div className='px-4 py-3 d-flex justify-content-between border-line mobile-menu-name'>
							<span>{profile.firstname}</span>
							<IoCloseCircleOutline
								className='c-pointer'
								size={25}
								color='mediumseagreen'
								onClick={() => setSidebar(false)}
							/>
						</div>
						<div
							className='px-4 py-3 border-line'
							onClick={() => {
								history.push('/select-location');
							}}
						>
							<span className='location-name'>
								<GrLocation className='location-icon' />
								{latLong?.location ? latLong?.location : 'Select a Location'}
								<br />
								<span className='store-name'>{store?.website_name}</span>
							</span>
						</div>
						<div
							className='px-4 py-3 border-line d-flex justify-content-between'
							onClick={() => setShowCat(true)}
						>
							All Categories
							<RiArrowRightSLine size={25} />
						</div>

						{/* <div
              className="px-4 py-3 border-line position-relative"
              onClick={() => {
                setCartbar(true);
                setSidebar(false);
              }}
            >
              <HiOutlineShoppingCart className="mr-2" />
              Cart
              <div className="user-action-indicator-sidebar">
                {cartData?.length}
              </div>
              {cartData && cartData?.length > 0 && (
                <div className="user-action-indicator-sidebar">
                  {cartData?.length}
                </div>
              )}
            </div> */}
						<div
							className='px-4 py-3 border-line d-flex justify-content-between'
							onClick={() => {
								// history.push("/account/profile");
								// setSidebar(false);
								setShowAccount(true);
							}}
						>
							Account <RiArrowRightSLine size={25} />
						</div>
					</div>
					<div>
						<div
							className='px-4 py-3 border-line d-flex align-item-center'
							onClick={handleLogout}
						>
							<IoLogOutOutline size={20} />
							Logout
						</div>
					</div>
				</div>
			)}
		</>
	);
}

const CartBar = ({ setSidebar }) => {
	const history = useHistory();
	const { isLoggedIn, cartData, setShowToast, setToastData, store } =
		useContext(AppContext);
	const [isDelete, setIsDelete] = useState();
	const [cartTotal, setCartTotal] = useState();
	const cartFixRef = useRef(null);

	useEffect(() => {
		if (isDelete === undefined) return;
		if (!isLoggedIn) return;
	}, [isDelete]);

	function handleSidebarLink(path) {
		if (path === '/checkout') {
			if (cartData?.filter((x) => x.is_salable !== 1).length > 0) {
				setToastData({
					type: 'error',
					text: 'Out of stock item(s) in your cart',
				});
				setShowToast(true);
				return;
			}
			if (
				cartTotal >=
				(process?.env?.REACT_APP_ENV === 'production'
					? minimumPrice[store?.sap_website_id]
					: 20)
			) {
				process?.env?.REACT_APP_ENV === 'production' &&
					TagManager.dataLayer({
						dataLayerName: 'dataLayer',
						dataLayer: {
							ecommerce: null,
						},
					});
				let prods = [];
				cartData?.map((i) => {
					prods.push({
						name: i?.name,
						id: i?.sku,
						price: i?.tax_included_price,
						brand: '',
						category: '',
						variant: '',
						quantity: i?.qty,
					});
				});
				process?.env?.REACT_APP_ENV === 'production' &&
					TagManager.dataLayer({
						dataLayerName: 'dataLayer',
						dataLayer: {
							event: 'checkout',
							ecommerce: {
								checkout: {
									actionField: { list: 'sidecart', step: 1 },
									products: prods,
								},
							},
						},
					});
				history.push('/checkout');
				setSidebar(false);
			} else {
				setShowToast(true);
				setToastData({
					type: 'error',
					text: `Minimum Cart Value should be AED ${
						process?.env?.REACT_APP_ENV === 'production'
							? minimumPrice[store?.sap_website_id]
							: 20
					}`,
				});
			}
		} else {
			setSidebar(false);
			history.push(path);
		}
	}

	const fixTwo = (x) => {
		let fix = Math.round(x * 100) / 100;
		return fix;
	};
	useEffect(() => {
		let mSubTotal = 0;
		let mTaxTotal = 0;
		cartData?.map((item) => {
			mSubTotal += fixTwo(item?.qty * item?.price);
			mTaxTotal += fixTwo(
				fixTwo(fixTwo(item?.qty * item?.price) * item?.tax_percentage) / 100
			);
		});

		setCartTotal(mSubTotal + mTaxTotal);
	}, [cartData]);
	return (
		<>
			<div className='d-flex border-line justify-content-between align-items-center p-3'>
				<p className='green-label m-0'>
					YOUR CART
					<span className='ml-2 faded-text'>
						{cartData?.length > 0
							? cartData?.length > 1
								? cartData?.length + ' items'
								: cartData?.length + ' item'
							: 'No items in cart'}
					</span>
				</p>
				<IoCloseCircleOutline
					className='c-pointer'
					size={25}
					color='mediumseagreen'
					onClick={() => setSidebar(false)}
				/>
				{/* <div className="sidebar-header-icon"></div> */}
			</div>
			{cartData?.length > 0 ? (
				<>
					<div
						className='sidebar-item-wrap'
						style={{
							height: `calc(100% - ${
								cartFixRef?.current?.offsetHeight + 58
							}px)`,
						}}
					>
						{cartData?.map((item, index) => {
							return (
								<SidebarCartItem
									key={`sidebar-cart-item-${index}-${item?.item_id}`}
									cartData={item}
									setIsDelete={setIsDelete}
									setSidebar={setSidebar}
								/>
							);
						})}
					</div>
					<div ref={cartFixRef} className='sidebar-fixed-box p-3'>
						<div className='d-flex justify-content-between mb-3'>
							<p className='paragraph-info m-0'>CART TOTAL</p>
							<p className='green-label m-0'>
								AED {parseFloat(cartTotal).toFixed(2)}
							</p>
						</div>
						<div className='d-flex justify-content-between flex-wrap mb-1 mb-d-0 cart-button-wrap'>
							<button
								className='secondary-button rounded-button flex-grow-1 click-anim cart-button-text'
								onClick={() => handleSidebarLink('/cart')}
							>
								GO TO CART
							</button>
							<button
								className={
									'primary-button rounded-button flex-grow-1 click-anim cart-button-text ' +
									(cartData?.length < 1 && ' button-disabled')
								}
								onClick={() => handleSidebarLink('/checkout')}
							>
								GO TO CHECKOUT
							</button>
						</div>
					</div>
				</>
			) : (
				<div className='empty-product'>
					<EmptyData
						picture={emptycart}
						heading={'Cart Empty'}
						description={'Add items to your cart to view them here'}
					/>
				</div>
			)}
		</>
	);
};

const MapBar = ({ setSidebar }) => {
	const { setShowToast, setToastData, updateAddressLocation } =
		useContext(AppContext);

	const [showMap, setShowMap] = useState(false);
	const [locationData, setLocationData] = useState({
		lat: 0,
		long: 0,
		name: '',
	});
	const containerStyle = {
		height: '60%',
		width: '100%',
	};

	const getLocationData = (latLng) => {
		Geocode.setApiKey(MAP_API_KEY);
		Geocode.fromLatLng(latLng.lat, latLng.lng).then(
			(res) => {
				setLocationData({
					lat: latLng?.lat,
					long: latLng?.lng,
					name: res?.results[0]?.formatted_address,
					city: {
						name: res?.results[0]?.address_components.filter(
							(item) => item?.types[0] === 'administrative_area_level_1'
						)[0]?.long_name,
						code: res?.results[0]?.address_components.filter(
							(item) => item?.types[0] === 'administrative_area_level_1'
						)[0]?.short_name,
					},
					country: {
						name: res?.results[0]?.address_components.filter(
							(item) => item?.types[0] === 'country'
						)[0]?.long_name,
						code: res?.results[0]?.address_components.filter(
							(item) => item?.types[0] === 'country'
						)[0]?.short_name,
					},
				});
			},
			(error) => {
				console.log(error);
			}
		);
	};

	const handleConfirmLocation = () => {
		checkLocation({
			point: {
				lat: locationData?.lat,
				long: locationData?.long,
			},
		}).then((res) => {
			if (!res?.data?.isPointInPolygon) {
				setShowToast(true);
				setToastData({
					type: 'error',
					text: 'Selected Location is Not Deliverable',
				});
			} else {
				updateAddressLocation({
					lat: locationData?.lat,
					long: locationData?.long,
					name: locationData?.name,
					store_id: res?.data?.stores[0]?.store_id,
					website_id: res?.data?.website_id,
					city: locationData?.city,
					country: locationData?.country,
				});

				setShowMap(false);
				setSidebar(false);
			}
		});
	};
	return (
		<>
			{showMap ? (
				<>
					<GoogleMap
						center={{
							lat: locationData?.lat,
							lng: locationData?.long,
						}}
						mapContainerStyle={containerStyle}
						zoom={16}
						clickableIcons={false}
						onClick={(e) => getLocationData(e.latLng.toJSON())}
					>
						<Marker
							position={{
								lat: locationData?.lat,
								lng: locationData?.long,
							}}
						/>
					</GoogleMap>
					<div className='d-flex justify-content-center flex-column my-2'>
						<div
							className='mx-4 my-2 position-relative d-flex justify-content-between p-3 align-items-center'
							style={{
								border: '1.1px solid #00983D',
								borderRadius: '6px',
							}}
						>
							<div className='overflow-hidden text-truncate w-80 mx-2'>
								{locationData?.name}
							</div>
							<div
								className='c-pointer fw-700 text-danger'
								onClick={() => {
									setShowMap(false);
								}}
							>
								Change
							</div>
						</div>
						<p
							className='text-center fs-16 mx-2'
							style={{
								color: '#7A869A',
							}}
						>
							Move the map pin to find your loaction and select the delivery
							address. Please place the pin accurately.
						</p>
						<button
							className='btn btn-success mx-5'
							onClick={handleConfirmLocation}
						>
							Confirm Location
						</button>
					</div>
				</>
			) : (
				<div className='container-fluid'>
					<div className='m-lg-4 mx-2 my-4'>
						<div className='d-flex justify-content-between align-items-center h3 mb-4'>
							<span>Search Location</span>
							<IoIosCloseCircle
								fill='green'
								size='1.3em'
								className='c-pointer'
								onClick={() => setSidebar(false)}
							/>
						</div>
						<span className='position-relative'>
							<HiOutlineLocationMarker
								stroke='green'
								size='1.3em'
								className='geo-suggest-input-icon'
							/>
							<Geosuggest
								placeholder='Search Location'
								inputClassName='form-control p-4 geo-suggest-input text-truncate'
								suggestsClassName='geo-suggest-suggests'
								suggestItemClassName='text-justify c-pointer py-2'
								onSuggestSelect={(e) => {
									if (e) {
										setLocationData({
											lat: e?.location?.lat,
											long: e?.location?.lng,
											name: e?.label,
											city: {
												name: e?.gmaps?.address_components.filter(
													(item) =>
														item?.types[0] === 'administrative_area_level_1'
												)[0]?.long_name,
												code: e?.gmaps?.address_components.filter(
													(item) =>
														item?.types[0] === 'administrative_area_level_1'
												)[0]?.short_name,
											},
											country: {
												name: e?.gmaps?.address_components.filter(
													(item) => item?.types[0] === 'country'
												)[0]?.long_name,
												code: e?.gmaps?.address_components.filter(
													(item) => item?.types[0] === 'country'
												)[0]?.short_name,
											},
										});
										setShowMap(true);
									}
								}}
							/>
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default Sidebar;
