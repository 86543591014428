import React, { useState, useEffect } from "react";
import CartCard from "../CartCard/CartCard";
import EmptyData from "../EmptyData";

import emptyFavs from "../../assets/images/favs-empty.svg";
import TagManager from "react-gtm-module";

const ProductGrid = props => {
  const [impressionList, setimpressionList] = useState([]);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    let i = 0;
    let tempData = [];
    props?.data?.map(item => {
      if (item?.type_id === "simple") {
        tempData[i] = item;
        i++;
      } else {
        item?.configurable_child_data?.map(configItem => {
          tempData[i] = configItem;
          i++;
        });
      }
    });
    setProducts(tempData);
  }, [props?.data]);
  useEffect(() => {
    if (impressionList?.length > 0) {
      process?.env?.REACT_APP_ENV === "production" &&
        TagManager.dataLayer({
          dataLayerName: "dataLayer",
          dataLayer: {
            ecommerce: null
          }
        });
      process?.env?.REACT_APP_ENV === "production" &&
        TagManager.dataLayer({
          dataLayerName: "dataLayer",
          dataLayer: {
            event: "productsViewed",
            ecommerce: {
              currencyCode: "AED",
              impressions: impressionList
            }
          }
        });
    }
  }, [impressionList]);

  return (
    <>
      <div className="container-fluid mx-0">
        <div className="row mb-4 mt-2">
          {typeof products?.length === "undefined" || products?.length !== 0 ? (
            products?.map((item, index) => {
              return (
                <div
                  className={
                    (props?.type === "nomenu"
                      ? "col-xl-2 col-lg-3 col-md-4"
                      : "col-xl-3 col-lg-4 col-md-3") + " col-sm-4 col-6 px-0"
                    // "col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 px-0" //remove line later
                  }
                  key={index + "-" + item?.sku}
                >
                  <CartCard
                    index={index}
                    className="cart-card-container-grid"
                    item={item}
                    from={props?.from}
                    setimpressionList={setimpressionList}
                    impressionList={impressionList}
                  />
                </div>
              );
            })
          ) : (
            <div className="empty-products col-12 my-4 py-2">
              <EmptyData
                picture={emptyFavs}
                heading={"No Products Found"}
                description={"This category does not have any Products?."}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ProductGrid;
