import "bootstrap/dist/css/bootstrap.css";
import useWindowSize from "../../hooks/useWindowSize";
import "../../pages/HomepageNew/HomepageNew.css";
import "./NoMarginBanner.css";
import NoMarginItem from "./widgets/NoMarginItem";
import NoMarginOnlyImage from "./widgets/NoMarginOnlyImage";

const NoMarginBanner = ({ componentDesignData }) => {
  const { width } = useWindowSize();

  return (
    <div className={width > 768 ? "container" : ""}>
      {componentDesignData?.subTemplate?.widget[0].widgetCode ===
        "NO_MARGIN_ONLY_IMAGE" && (
        <NoMarginOnlyImage componentDesignData={componentDesignData} />
      )}

      {componentDesignData?.subTemplate?.widget[0].widgetCode ===
        "NO_MARGIN_ITEM" && (
        <NoMarginItem componentDesignData={componentDesignData} />
      )}
    </div>
  );
};

export default NoMarginBanner;
