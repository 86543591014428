import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";

const SquareGridItem = ({ widget, index }) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleWidgetRedirect = () => {
    let url = getRedirectionUrl(
      widget?.details?.redirectType,
      widget?.details?.itemCode
    );
    history.push(url);
  };
  return (
    <div
      key={widget._id}
      className={`d-flex flex-column align-items-center justify-content-between grid-item ${
        index === 0 || index === 7 ? "Grid_cornerItems" : ""
      }`}
      onClick={handleWidgetRedirect}
    >
      <div
        className="square-grid-image-wrapper outer_circle"
        style={{
          borderColor: `linear-gradient(${widget.details.borderStartGradient} 100%, ${widget.details.borderEndGradient} 100%)`,
          // width: "1rem",
          background: `linear-gradient(180deg, ${widget.details.startGradient} 0%, ${widget.details.endGradient} 100%)`,
        }}
      >
        <img className="grid-image" src={widget.details.imageUrl} alt="jj" />
      </div>
      <div
        className="grid-title square-grid-title"
        style={{ marginBottom: "1rem", textAlign: "center" }}
      >
        {widget.details.title}
      </div>
    </div>
  );
};

export default SquareGridItem;
