import React, { useEffect, useState, useContext } from "react";
import { MdDeleteForever } from "react-icons/md";
import CartButton from "../CartButton/CartButton";
import box from "../../assets/images/placeholder.svg";
import { IMAGE_BASE_URL } from "../../api/config";
import { deleteFromCart, addToCart, updateToCart } from "../../api";
import { AppContext } from "../../context/AppContext";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router";
import "./CartItem.css";

function CartItem(props) {
  return props?.type === "orderedit" ? (
    <OrderEditItem
      orderItemDataEdit={props?.orderItemDataEdit}
      setOrderItemDataEdit={props?.setOrderItemDataEdit}
      setOrderItemData={props?.setOrderItemData}
      orderItemData={props?.orderItemData}
      setOrderItems={props?.setOrderItems}
      orderItems={props?.orderItems}
      orderData={props?.orderData}
      data={props?.data}
      setIsDelete={props?.setIsDelete}
    />
  ) : (
    <CartEditItem
      type={props?.type}
      data={props?.data}
      setIsDelete={props?.setIsDelete}
    />
  );
}

function OrderEditItem(props) {
  const history = useHistory();
  const [cartValue, setCartValue] = useState(
    parseInt(props?.data?.qty_ordered)
  );
  const [updatedValue, setUpdatedValue] = useState(
    parseInt(props?.data?.qty_ordered)
  );
  const { setShowToast, setToastData } = useContext(AppContext);

  // const [orderItems, setOrderItems] = useState(props?.orderData);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [hasClicked, setHasClicked] = useState(0);

  function handleOrderEdit() {
    if (!props?.orderItems) {
      props?.setOrderItems([
        { id: props?.data?.item_id, sku: props?.data?.sku, qty: cartValue }
      ]);
    } else {
      let mOrderItems = [];
      let isInOrderItems = false;
      mOrderItems = props?.orderItems?.map(item => {
        if (item?.sku === props?.data?.sku) {
          item.qty = cartValue;
          isInOrderItems = true;
        }
        return item;
      });
      // props?.setOrderItems(mOrderItems);
      if (!isInOrderItems) {
        props?.setOrderItems([
          ...props?.orderItems,
          { id: props?.data?.item_id, sku: props?.data?.sku, qty: cartValue }
        ]);
      }

      // props?.setOrderItems([...props?.orderItems, { id: props?.data?.item_id, sku: props?.data?.sku, qty: cartValue }]);
    }

    let mOrderData = [];
    mOrderData = props?.orderItemDataEdit.map(item => {
      if (item?.sku === props?.data?.sku) {
        item.qty_ordered = cartValue;
      }
      return item;
    });
    props?.setOrderItemDataEdit(mOrderData);

    setUpdatedValue(cartValue);
  }

  useEffect(() => {
    if (hasClicked === 0) return;
    if (cartValue <= 0) {
      setCartValue(0);
      setUpdatedValue(0);
    }
    handleOrderEdit();
  }, [hasClicked]);

  function handleCartItemDelete() {
    setCartValue(0);
    setUpdatedValue(0);
    setHasClicked(hasClicked + 1);
  }

  function handleCartValue(e) {
    if (
      props?.data?.salable_qty[0]?.manage_stock &&
      e > props?.data?.salable_qty[0]?.qty + props?.data?.qty_ordered
    ) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "Requested quantity is not available."
      });
    } else {
      setCartValue(e);
    }
  }

  const onCartItemClick = () => {
    process?.env?.REACT_APP_ENV === "production" &&
      TagManager.dataLayer({
        dataLayerName: "dataLayer",
        dataLayer: {
          ecommerce: null
        }
      });
    process?.env?.REACT_APP_ENV === "production" &&
      TagManager.dataLayer({
        dataLayerName: "dataLayer",
        dataLayer: {
          event: "productClick",
          ecommerce: {
            click: {
              actionField: { list: "orderEdit" }, // Optional list property.
              products: [
                {
                  name: props?.data?.name, // Name or ID is required.
                  id: props?.data?.sku,
                  price: props?.data?.tax_included_price,
                  brand: "",
                  category: "",
                  variant: "",
                  position: 0
                }
              ]
            }
          }
        }
      });
    history.push(`/products/${props.data.sku}`);
  };

  return (
    <tr className="border-line my-2 row flex-wrap">
      <td className="col-md-6">
        <div className="d-flex align-items-center">
          <div className="position-relative cart-page-product-img-wrap">
            <img
              className="cart-page-product-img mr-3"
              src={
                props?.data?.image
                  ? IMAGE_BASE_URL +
                    "/catalog/product" +
                    props?.data?.image.replace("/media" || "/product", "")
                  : box
              }
              alt="product"
            />
            {cartValue > 0 && (
              <button
                className="p-0 delete-button click-anim cart-item-delete mt-3 d-flex justify-content-center align-items-center"
                onClick={handleCartItemDelete}
              >
                <MdDeleteForever className="text-white fs-16" />
              </button>
            )}
          </div>
          <div
            className="m-0 paragraph-info c-pointer"
            onClick={onCartItemClick}
          >
            {props?.data?.name}
          </div>
        </div>
      </td>
      <td className="green-label col-md-2 text-nowrap text-center text-md-left my-2 my-md-0">{`AED ${parseFloat(
        props?.data?.price_incl_tax
      ).toFixed(2)}`}</td>
      <td className="col-md-2">
        <CartButton
          loaderColor="#00983d"
          bg="white"
          color="black"
          type="compact"
          updatedValue={updatedValue}
          hasClicked={hasClicked}
          setHasClicked={setHasClicked}
          isCartLoading={isCartLoading}
          cartValue={cartValue}
          setCartValue={handleCartValue}
        />
      </td>
      <td className="green-label col-md-2 position-relative text-nowrap text-center text-md-left my-2 my-md-0">
        <div>
          {`AED
          ${(
            updatedValue * parseFloat(props?.data?.price) +
            (updatedValue *
              parseFloat(props?.data?.price) *
              parseFloat(props?.data?.tax_percent)) /
              100
          ).toFixed(2)}`}
        </div>
      </td>
    </tr>
  );
}

function CartEditItem(props) {
  const history = useHistory();
  const {
    setShowToast,
    setToastData,
    currentStore,
    addToCartContext,
    updateCartContext,
    removeFromCartContext,
    cartId
  } = useContext(AppContext);
  const [cartValue, setCartValue] = useState();
  const [updatedValue, setUpdatedValue] = useState();
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [isCart, setIsCart] = useState(true);
  const [hasClicked, setHasClicked] = useState(0);
  const cartDataLogger = (eventName, data) => {
    process?.env?.REACT_APP_ENV === "production" &&
      TagManager.dataLayer({
        dataLayerName: "dataLayer",
        dataLayer: {
          ecommerce: null
        }
      });
    if (eventName === "addToCart") {
      process?.env?.REACT_APP_ENV === "production" &&
        TagManager.dataLayer({
          dataLayerName: "dataLayer",
          dataLayer: {
            event: eventName,
            ecommerce: {
              currencyCode: "AED",
              add: {
                // 'add' actionFieldObject measures.
                products: [
                  {
                    //  adding a product to a shopping cart.
                    name: data?.name,
                    id: data?.sku,
                    price:
                      props?.data?.tax_included_special_price > 0
                        ? props?.data?.tax_included_special_price || 0
                        : props?.data?.tax_included_price,
                    brand: "",
                    category: "",
                    variant: "",
                    quantity: data?.qty
                  }
                ]
              }
            }
          }
        });
    } else {
      process?.env?.REACT_APP_ENV === "production" &&
        TagManager.dataLayer({
          dataLayerName: "dataLayer",
          dataLayer: {
            event: eventName,
            ecommerce: {
              currencyCode: "AED",
              remove: {
                // 'add' actionFieldObject measures.
                products: [
                  {
                    //  adding a product to a shopping cart.
                    name: data?.name,
                    id: data?.sku,
                    price:
                      props?.data?.tax_included_special_price > 0
                        ? props?.data?.tax_included_special_price || 0
                        : props?.data?.tax_included_price,
                    brand: "",
                    category: "",
                    variant: "",
                    quantity: data?.qty
                  }
                ]
              }
            }
          }
        });
    }
  };
  function handleCart(methodToDo) {
    setIsCartLoading(true);
    const addToCartData = {
      cartItem: {
        quote_id: cartId,
        sku: props?.data?.sku,
        qty: cartValue
      }
    };
    switch (methodToDo) {
      case "addToCart":
        setIsCart(true);
        addToCart(currentStore?.store_code, addToCartData)
          .then(res => {
            cartDataLogger("addToCart", res);
            addToCartContext(res?.item_id, props.data, res?.qty);
            setShowToast(true);
            setToastData({
              type: "success",
              text: "Product Added"
            });
            setUpdatedValue(cartValue);
          })
          .catch(e => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          });
        break;
      case "updateToCart":
        updateToCart(
          currentStore?.store_code,
          props?.data?.item_id,
          addToCartData
        )
          .then(res => {
            cartDataLogger("addToCart", res);
            updateCartContext(res?.item_id, res?.qty);
            setShowToast(true);
            setToastData({
              type: "success",
              text: "Product Updated"
            });
            setUpdatedValue(cartValue);
          })
          .catch(e => {
            setCartValue(oldCartValue => oldCartValue - 1);

            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          })
          .finally(() => {
            setIsCartLoading(false);
          });
        break;
      case "deleteFromCart":
        setIsCart(false);
        deleteFromCart(currentStore?.store_code, props?.data?.item_id)
          .then(res => {
            if (res) {
              cartDataLogger("removeFromCart", props?.data);
              removeFromCartContext(props?.data?.item_id);
              setShowToast(true);
              setToastData({
                type: "success",
                text: "Product Removed"
              });
            }
          })
          .catch(e => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          })
          .finally(() => {
            setIsCartLoading(false);
          });
        break;
    }
  }

  useEffect(() => {
    if (hasClicked === 0) return;
    if (isCart && cartValue == 0) return handleCart("deleteFromCart");
    if (isCart && cartValue > 0) return handleCart("updateToCart");
    if (!isCart && cartValue > 0) return handleCart("addToCart");
  }, [hasClicked]);

  useEffect(() => {
    setCartValue(props?.data?.qty);
    setUpdatedValue(props?.data?.qty);
    setIsCartLoading(false);
  }, [props?.data?.qty]);

  function handleNoImage(el) {
    el.target.src = box;
  }

  const onCartItemClick = () => {
    process?.env?.REACT_APP_ENV === "production" &&
      TagManager.dataLayer({
        dataLayerName: "dataLayer",
        dataLayer: {
          ecommerce: null
        }
      });
    process?.env?.REACT_APP_ENV === "production" &&
      TagManager.dataLayer({
        dataLayerName: "dataLayer",
        dataLayer: {
          event: "productClick",
          ecommerce: {
            click: {
              actionField: { list: "orderEdit" }, // Optional list property.
              products: [
                {
                  name: props?.data?.name, // Name or ID is required.
                  id: props?.data?.sku,
                  price:
                    props?.data?.tax_included_special_price > 0
                      ? props?.data?.tax_included_special_price || 0
                      : props?.data?.tax_included_price,
                  brand: "",
                  category: "",
                  variant: "",
                  position: 0
                }
              ]
            }
          }
        }
      });
    history.push(`/products/${props?.data?.sku}`);
  };

  return (
    <tr className="border-line my-2 row flex-wrap">
      <td className="col-md-6">
        <div className="d-flex align-items-center">
          <div className="position-relative cart-page-product-img-wrap">
            <img
              className="cart-page-product-img mr-3"
              src={
                props?.data?.image
                  ? IMAGE_BASE_URL +
                    "/catalog/product" +
                    props?.data?.image.replace("/media" || "/product", "")
                  : box
              }
              style={{ cursor: "pointer" }}
              onError={handleNoImage}
              onClick={onCartItemClick}
              alt="product"
            />
            {props.type === "cart" ? (
              <button
                className="p-0 delete-button click-anim cart-item-delete mt-3 d-flex justify-content-center align-items-center"
                onClick={() => handleCart("deleteFromCart")}
              >
                <MdDeleteForever className="text-white fs-16" />
              </button>
            ) : (
              <button className="p-0 delete-button click-anim cart-item-delete mt-3 d-flex justify-content-center align-items-center">
                <MdDeleteForever className="text-white fs-16" />
              </button>
            )}
          </div>
          <div>
            {0 < props?.data?.salable_qty[0]?.qty &&
            props?.data?.salable_qty[0]?.qty <= cartValue ? (
              <div className="hurry-items-sidebar">
                Hurry! only {props?.data?.salable_qty[0]?.qty} left
              </div>
            ) : (
              <></>
            )}
            <p
              className="m-0 paragraph-info"
              style={{ cursor: "pointer" }}
              onClick={onCartItemClick}
            >
              {props?.data?.name}
            </p>
            {props?.data?.is_salable === 0 ||
            props?.data?.salable_qty[0]?.qty === 0 ? (
              <p className="m-0 no-stock-cart">Out of Stock</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </td>
      {props?.data?.is_salable === 0 ||
      props?.data?.salable_qty[0]?.qty === 0 ? (
        <td colSpan="3"></td>
      ) : (
        <>
          <td className="green-label col-md-2 text-nowrap text-center text-md-left my-2 my-md-0">{`AED ${parseFloat(
            props?.data?.tax_included_price
          ).toFixed(2)}`}</td>
          <td className="col-md-2">
            {/* {props.type === "cart" ? ( */}
            <CartButton
              loaderColor="#00983d"
              bg="white"
              color="black"
              type="compact"
              sku={props?.data?.sku}
              updatedValue={updatedValue}
              hasClicked={hasClicked}
              setHasClicked={setHasClicked}
              isCartLoading={isCartLoading}
              cartValue={cartValue}
              setCartValue={setCartValue}
            />
            {/* ) : ( */}
            {/* <CartButton
            bg="white"
            color="black"
            type="compact"
            isCartLoading={isCartLoading}
            cartValue={itemValue}
            setCartValue={setItemValue}
          /> */}
            {/* )} */}
          </td>
          <td className="green-label col-md-2 position-relative text-nowrap text-center text-md-left my-2 my-md-0">
            <div>{`AED ${(
              parseFloat(props?.data?.qty) * parseFloat(props?.data?.price) +
              (parseFloat(props?.data?.qty) *
                parseFloat(props?.data?.price) *
                parseFloat(props?.data?.tax_percentage)) /
                100
            ).toFixed(2)}`}</div>
          </td>
        </>
      )}
    </tr>
  );
}

export default CartItem;
