import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";

const NoMarginOnlyImage = ({ componentDesignData }) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };
  return (
    <div
      style={{ width: "100%", cursor: "pointer" }}
      onClick={() =>
        handleRedirect(
          componentDesignData?.subTemplate?.widget[0].details.redirectType,
          componentDesignData?.subTemplate?.widget[0].details.itemCode
        )
      }
    >
      <img
        style={{ width: "100%" }}
        src={componentDesignData?.subTemplate?.widget[0].details.imageUrl}
        alt={componentDesignData?.subTemplate?.widget[0].details.name}
      />
    </div>
  );
};

export default NoMarginOnlyImage;
