import "bootstrap/dist/css/bootstrap.css";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import "../../pages/HomepageNew/HomepageNew.css";

const AdBanner = ({ componentDesignData }) => {
  const [widgetData, setWidgetData] = useState({});
  const { width } = useWindowSize();
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    setWidgetData({ ...componentDesignData?.subTemplate?.widget?.[0] });
  }, [componentDesignData]);

  return (
    <div
      className="container"
      style={{
        width: "100%",
        margin: "auto",
        marginTop: width > 445 ? "1rem" : "0.5rem",
        marginBottom: width > 445 ? "1rem" : "0.5rem",
        maxHeight: width > 900 ? "50vh" : "auto",
        cursor: "pointer",
      }}
      onClick={() =>
        history.push(
          getRedirectionUrl(
            widgetData.details.redirectType,
            widgetData.details.itemCode
          )
        )
      }
    >
      <img
        src={widgetData?.details?.imageUrl}
        alt="Ad-Banner"
        style={{ width: "100%", maxHeight: width > 900 ? "50vh" : "auto" }}
      />
    </div>
  );
};

export default AdBanner;
