import React, { useContext, useEffect, useState } from "react";
import "./Footer.css";
import logo from "../../assets/images/nesto-group-logo2.svg";
import payIcon from "../../assets/images/pay-icons.svg";
import flyerOne from "../../assets/images/nesto-inaam-rewards.png";
import bannerPhoneOne from "../../assets/images/nesto-online-app4.png";
import bannerPhoneTwo from "../../assets/images/nesto-online-app5.png";
import bannerLogo from "../../assets/images/nesto-group-logo.png";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaWhatsapp
} from "react-icons/fa";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import { BiPhone } from "react-icons/bi";
import { BsArrowUp } from "react-icons/bs";
import { BiArrowToTop } from "react-icons/bi";
import { useHistory, useLocation } from "react-router";
import { Accordion } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";

const Footer = () => {
  const history = useHistory();
  const location = useLocation();
  const [urls, setUrls] = useState({});

  const { currentStore, isLoggedIn, setShowToast, setToastData, setShowLogin } =
    useContext(AppContext);
  useEffect(() => {
    if (process?.env?.REACT_APP_ENV === "production") {
      setUrls({
        grocery: 2612,
        beverages: 2678,
        breakfast: 2637,
        condiments: 2613,
        canned: 2632
      });
    } else {
      setUrls({
        grocery: 1630,
        beverages: 1694,
        breakfast: 1655,
        condiments: 1631,
        canned: 1650
      });
    }
  }, []);

  const pageNavigation = (url, authRequired = false) => {
    if (!isLoggedIn & authRequired) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Login to Continue"
      });
      setShowLogin(true);
      return 0;
    }
    if (currentStore?.is_active !== "1") {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Select Location to Continue"
      });
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return 0;
    }
    history.push(url);
  };

  return (
    <div className="footer pt-2">
      <div className="top-footer">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 pr-md-5 mb-3 mb-md-0 c-pointer"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
                pageNavigation("/account/inaam", true);
              }}
            >
              <img className="w-100" src={flyerOne} alt="Nesto Inaam Rewards" />
            </div>
            <div className="col-md-6 pl-md-5">
              <div className="banner-store h-100">
                <img
                  className="bannerPhone-one"
                  src={bannerPhoneOne}
                  alt="Nesto Online App4"
                />
                <img
                  className="bannerPhone-two"
                  src={bannerPhoneTwo}
                  alt="Nesto Online App5"
                />
                <div className="row h-100">
                  <div className="offset-md-6 col-md-6 d-flex flex-column justify-content-center align-items-center h-100 py-2">
                    <img
                      className="banner-store-logo mb-2"
                      src={bannerLogo}
                      alt="Nesto Group Logo"
                    />
                    <p className="banner-store-heading">
                      Get the <span>Nesto App</span>
                    </p>
                    <button
                      className="footer-app-download appstore c-pointer"
                      onClick={() =>
                        (window.location.href =
                          "https://apps.apple.com/in/app/nesto-online-shopping/id1560646978")
                      }
                    ></button>
                    <button
                      className="footer-app-download googleplay c-pointer"
                      onClick={() =>
                        (window.location.href =
                          "https://play.google.com/store/apps/details?id=shop.nesto.ecommerce")
                      }
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-md-start">
                <p
                  className="middle-footer-info mb-md-0 mb-3 c-pointer"
                  onClick={() => window.open("tel:+971-48129700", "_blank")}
                >
                  <BiPhone className="mr-2" size={22} />
                  +971-48129700
                </p>
                <p
                  className="middle-footer-info mb-md-0 mb-3 c-pointer"
                  onClick={() =>
                    window.open("https://wa.me/97148129700", "_blank")
                  }
                >
                  <AiOutlineWhatsApp className="mr-2" size={22} />
                  +971-48129700
                </p>
                <p
                  className="middle-footer-info mb-md-0 mb-3 c-pointer"
                  onClick={() =>
                    window.open(
                      "mailto:support@nesto.shop?subject=Website Support Required!"
                    )
                  }
                >
                  <FiMail className="mr-2" size={22} />
                  support@nesto.shop
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                <p className="middle-footer-label mr-4">Stay Connected</p>
                <div className="middle-footer-social d-flex justify-content-between">
                  <button
                    className="middle-footer-social-links"
                    onClick={() =>
                      (window.location.href =
                        "https://www.facebook.com/nestoshopuae")
                    }
                  >
                    <FaFacebook size={26} />
                  </button>
                  {/* <button
                    className="middle-footer-social-links"
                    onClick={() =>
                      (window.location.href = "https://nestogroup.com/")
                    }
                  >
                    <AiOutlineTwitter size={26} />
                  </button> */}
                  <button
                    className="middle-footer-social-links"
                    onClick={() =>
                      (window.location.href =
                        "https://www.linkedin.com/company/nestogroup/")
                    }
                  >
                    <FaLinkedin size={26} />
                  </button>
                  <button
                    className="middle-footer-social-links"
                    onClick={() =>
                      (window.location.href =
                        "https://www.instagram.com/nesto.shop/")
                    }
                  >
                    <FaInstagram size={26} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fat-footer">
        <div className="container-fluid">
          <div className="row align-items-center ">
            <div className="col-md-3 text-center">
              <img
                src={logo}
                alt="Nesto Group Logo"
                className="fat-footer-logo"
              ></img>
              {/* <p className="fat-footer-company-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mi ut auctor nec sed suspendisse.</p> */}
            </div>
            <div className="col-md-9 fat-footer-bg">
              <div className="row">
                <div className="col-md-3">
                  {/* <Accordion defaultActiveKey="0">
                    <Accordion.Toggle eventKey="0"> */}
                  <p className="fat-footer-list-title">Get To Know Us</p>
                  {/* </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0"> */}
                  <button
                    className="fat-footer-list-link"
                    onClick={() => pageNavigation("/account/about", true)}
                  >
                    About Us
                  </button>
                  {/* <button className="fat-footer-list-link">Contact Us</button>
                  <button className="fat-footer-list-link">Help Center</button> */}
                  {/* </Accordion.Collapse>
                  </Accordion> */}
                </div>
                <div className="col-md-3">
                  <p className="fat-footer-list-title">More From Us</p>
                  {/* <button className="fat-footer-list-link">Feedback</button>
                  <button className="fat-footer-list-link">Advanced Search</button> */}
                  <button
                    className="fat-footer-list-link"
                    onClick={() => {
                      pageNavigation("/account/orders", true);
                    }}
                  >
                    Orders &amp; Returns
                  </button>
                  <button
                    className="fat-footer-list-link"
                    onClick={() => pageNavigation("/account/orders", true)}
                  >
                    View Status Of Order
                  </button>
                </div>
                <div className="col-md-3">
                  <p className="fat-footer-list-title">Policies</p>
                  <button
                    className="fat-footer-list-link"
                    onClick={() =>
                      (window.location.href =
                        "https://docs.nesto.shop/Privacy.pdf")
                    }
                  >
                    Privacy Policy
                  </button>
                  <button
                    className="fat-footer-list-link"
                    onClick={() =>
                      (window.location.href =
                        "https://docs.nesto.shop/Terms.html")
                    }
                  >
                    Terms &amp; Conditions
                  </button>
                  {/* <button className="fat-footer-list-link">Contact Us</button>
                  <button className="fat-footer-list-link">Return Policy</button>
                  <button className="fat-footer-list-link">Legal</button>
                  <button className="fat-footer-list-link">Cookie Policy</button> */}
                </div>
                <div className="col-md-3">
                  <p className="fat-footer-list-title">In The Spotlight</p>
                  <button
                    className="fat-footer-list-link"
                    onClick={() =>
                      pageNavigation("/categories/" + urls.grocery, false)
                    }
                  >
                    Pantry Supplies
                  </button>
                  <button
                    className="fat-footer-list-link"
                    onClick={() =>
                      pageNavigation("/categories/" + urls.beverages, false)
                    }
                  >
                    Beverages
                  </button>
                  <button
                    className="fat-footer-list-link"
                    onClick={() =>
                      pageNavigation("/categories/" + urls.breakfast, false)
                    }
                  >
                    Breakfast &amp; Cereal
                  </button>
                  <button
                    className="fat-footer-list-link"
                    onClick={() =>
                      pageNavigation("/categories/" + urls.condiments, false)
                    }
                  >
                    Condiments
                  </button>
                  <button
                    className="fat-footer-list-link"
                    onClick={() =>
                      pageNavigation("/categories/" + urls.canned, false)
                    }
                  >
                    Canned Goods
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "fat-footer-base d-flex justify-content-between align-items-center flex-wrap" +
          (location.pathname.match("/products") ? " pdp-footer" : "")
        }
      >
        <p className="fat-footer-rights-info">
          {`V${process?.env?.REACT_APP_VERSION} © 2021 Nesto Ltd. Trademarks and brands`}
        </p>
        <img
          className="fat-footer-pay-icons"
          src={payIcon}
          alt="pay-icons"
        ></img>
      </div>
    </div>
  );
};

export default Footer;
