import "bootstrap/dist/css/bootstrap.css";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import "./OfferBanner.css";

const OfferBanner = ({ componentDesignData }) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };

  return (
    <div className="container">
      <div
        className="d-flex align-items-center justify-content-between r-075 OfferBanner"
        onClick={() =>
          handleRedirect(
            componentDesignData?.subTemplate?.widget[0].details.redirectType,
            componentDesignData?.subTemplate?.widget[0].details.itemCode
          )
        }
        style={{
          color: "white",
          cursor: "pointer",
          background: `linear-gradient(93.58deg, ${componentDesignData?.subTemplate?.widget[0].details.startGradient} 0%, ${componentDesignData?.subTemplate?.widget[0].details.endGradient} 100%)`,
        }}
      >
        <div className="offer-banner-wrapper">
          <>
            <span className="offer-banner-title">
              {componentDesignData?.subTemplate?.widget[0].details.title}
            </span>{" "}
            <span className="offer-banner-discount">
              {
                componentDesignData?.subTemplate?.widget[0].details
                  .discountLabel
              }
            </span>
          </>
        </div>
        <img
          src={componentDesignData?.subTemplate?.widget[0].details.imageUrl}
          alt="discount-banner"
          className="offer-banner-image"
        />
      </div>
    </div>
  );
};

export default OfferBanner;
