import "bootstrap/dist/css/bootstrap.css";
import { useContext } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router-dom";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import LaptopImage from "./assets/image-62.jpg";
import "./DynamicSectionSlider.css";

SwiperCore.use([Navigation, Pagination]);

const data = {
  title: "Shop Latest Laptops now",
  widget: [
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
    {
      name: "Acer Laptop with 64GB RAM and 1TB Storage",
      price: 1000,
    },
  ],
};

const DynamicSectionSlider = ({ componentDesignData }) => {
  const { width } = useWindowSize();

  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };

  return (
    <div
      className={`container dynamic-slider-${componentDesignData?.position} dynamicSectionSlider`}
    >
      <div>
        <div
          className="d-flex justify-content-between justify-content-md-start align-items-center"
          style={{ marginTop: "0.5rem" }}
        >
          <h1 className="homepage_title">
            {componentDesignData?.subTemplate?.details.title}
          </h1>
          <div
            style={{
              color: "#2D9140",
              marginLeft: "0.5rem",
              marginBottom: "12px",
              fontWeight: "700",
              fontSize: "0.875rem",
              cursor: "pointer",
            }}
            onClick={() =>
              handleRedirect(
                componentDesignData?.subTemplate?.details?.redirectType,
                componentDesignData?.subTemplate?.details?.itemCodex
              )
            }
          >
            View all <IoIosArrowForward />
          </div>
        </div>

        <div className="swipe-arrows">
          <div className="swipe-button-prev">
            <FiChevronLeft className="swipe-arrow" />
          </div>
          <div className="swipe-button-next">
            <FiChevronRight className="swipe-arrow" />
          </div>
          <Swiper
            spaceBetween={10}
            freeMode={true}
            slidesPerView={"auto"}
            navigation={{
              nextEl: `.dynamic-slider-${componentDesignData?.position} .swipe-button-next`,
              prevEl: `.dynamic-slider-${componentDesignData?.position} .swipe-button-prev`,
            }}
            style={{ marginLeft: 0, marginRight: 0 }}
            observer={true}
          >
            {data.widget.map((widget, index) => (
              <SwiperSlide key={"shopby-" + index} style={{ flexShrink: "1" }}>
                <div
                  className="DynamicSection__product d-inline-flex flex-column align-items-stretch justify-content-between"
                  // style={{ width: "70%", margin: "auto", padding: "0.5rem" }}a
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0.8rem",
                      right: "0.8rem",
                    }}
                  >
                    <AiOutlineHeart
                      className="dynamic-slider-heart-icon"
                      style={{ fontWeight: "lighter", strokeWidth: "1px" }}
                      size={width < 425 ? 20 : 25}
                      color={"#747474"}
                    />
                  </div>
                  <img src={LaptopImage} alt="Laptop" />
                  <div>
                    <div
                      className="dynamic-section-product-title"
                      // style={{
                      //   fontWeight: "325",
                      //   lineHeight: "22px",
                      //   fontSize: "0.825rem",
                      //   marginTop: "1rem",
                      //   marginBottom: "0.25rem",
                      // }}
                    >
                      {widget.name}
                    </div>
                    <div
                      className="dynamic-section-price"
                      style={{ fontWeight: "bold", fontSize: "0.825rem" }}
                    >
                      AED {widget.price}
                    </div>
                  </div>

                  <button
                    className="dynamicSectionSlider__button"
                    style={{
                      marginTop: "1rem",
                      borderRadius: "0.4rem",
                      width: "100%",
                      marginLeft: "0",
                      marginRight: "0",
                    }}
                  >
                    Explore
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default DynamicSectionSlider;
