import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BoughtBefore from "../../components/BoughtBefore/BoughtBefore";
import Carousel from "../../components/Carousel/Carousel";
import DealsView from "../../components/DealsView/DealsView";
import DynamicSectionList from "../../components/DynamicSectionList/DynamicSectionList";
import DynamicSectionSlider from "../../components/DynamicSectionSlider/DynamicSectionSlider";
import Grid from "../../components/Grid/Grid";
import GridView from "../../components/GridView/GridView";
import NoMarginBanner from "../../components/NoMarginBanner/NoMarginBanner";
import OfferBanner from "../../components/OfferBanner/OfferBanner";
import ThreeGridBanner from "../../components/ThreeBannerGrid/ThreeBannerGrid";
import TwoBannerGrid from "../../components/TwoBannerGrid/TwoBannerGrid";
import AdBanner from "../../components/AdBanner/AdBanner";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import "./TemplatePage.css";

const TemplatePage = () => {
  const [MainPageData, setMainPageData] = useState([]);
  const { appDesignData, setBreadcrumbs } = useContext(AppContext);
  const { width } = useWindowSize();
  const { pageId } = useParams();

  useEffect(() => {
    setBreadcrumbs([]);
  }, []);

  useEffect(() => {
    const getPageData = async () => {
      const pageData = await appDesignData?.data?.data?.pages.filter(
        (page) => page._id === pageId
      )[0];
      if (pageData) {
        setMainPageData({ ...pageData });
      }
    };
    getPageData();
  }, [appDesignData]);

  function compare(a, b) {
    if (a?.position < b?.position) {
      return -1;
    }
    if (a?.position > b?.position) {
      return 1;
    }
    return 0;
  }

  const orderTemplatePage = (section) => {
    switch (section?.subTemplate?.sectionCode) {
      case "FULL_IMAGE_CAROUSEL":
        return <Carousel componentDesignData={section} key={section?._id} />;
      case "GRID":
        return <Grid componentDesignData={section} key={section?._id} />;

      case "AD_BANNER":
        return <AdBanner componentDesignData={section} key={section?._id} />;

      case "THREE_BANNER_GRID":
        return (
          <ThreeGridBanner componentDesignData={section} key={section?._id} />
        );

      case "OFFER_BANNER":
        return <OfferBanner componentDesignData={section} key={section?._id} />;

      case "DYNAMIC_SECTION_SLIDER":
        return section?.subTemplate?.details?.listType ===
          "bought_before_grocery" ? (
          <BoughtBefore componentDesignData={section} key={section?._id} />
        ) : (
          <DynamicSectionSlider
            componentDesignData={section}
            key={section?._id}
          />
        );
      case "NO_MARGIN_BANNER":
        return (
          <NoMarginBanner componentDesignData={section} key={section?._id} />
        );
      case "GRID_VIEW":
        return <GridView componentDesignData={section} key={section?._id} />;
      case "TWO_BANNER_GRID":
        return (
          <TwoBannerGrid componentDesignData={section} key={section?._id} />
        );
      case "DEALS_VIEW":
        return <DealsView componentDesignData={section} key={section?._id} />;
      case "DYNAMIC_SECTION_LIST":
        <DynamicSectionList componentDesignData={section} key={section?._id} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="template-page">
      {MainPageData?.content?.sort(compare).map((section, index) => {
        return orderTemplatePage(section);
      })}
    </div>
  );
};

export default TemplatePage;
