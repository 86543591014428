import "bootstrap/dist/css/bootstrap.css";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import "./ThreeBannerGrid.css";

const ThreeGridBanner = ({ componentDesignData }) => {
  const { getRedirectionUrl } = useContext(AppContext);
  const { width } = useWindowSize();
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };

  return (
    <div className="container ThreeGridBanner">
      <h2 className="homepage_title">
        {componentDesignData?.subTemplate?.details?.title}
      </h2>

      <div className="grid-container">
        <div
          className="full-width-banner r-075"
          style={{
            background: `linear-gradient(284.6deg, ${componentDesignData?.subTemplate?.widget[0].details.startGradient} 0%, ${componentDesignData?.subTemplate?.widget[0].details.endGradient} 100%)`,
          }}
          onClick={() => {
            width < 1000
              ? handleRedirect(
                  componentDesignData?.subTemplate?.widget[0].details
                    ?.redirectType,
                  componentDesignData?.subTemplate?.widget[0].details?.itemCode
                )
              : console.log("");
          }}
        >
          <div className="full-banner-heading-button-wrapper">
            <h3
              className="full-banner-title"
              style={{
                color:
                  componentDesignData?.subTemplate?.widget[0].details.fontColor,
              }}
            >
              {componentDesignData?.subTemplate?.widget[0].details.title}
            </h3>
            {width > 1000 && (
              <button
                className="full-banner-cta-btn cta_btn"
                style={{
                  background:
                    componentDesignData?.subTemplate?.widget[0].details
                      .buttonColor,
                  color:
                    componentDesignData?.subTemplate?.widget[0].details
                      .buttonFontColor,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleRedirect(
                    componentDesignData?.subTemplate?.widget[0].details
                      ?.redirectType,
                    componentDesignData?.subTemplate?.widget[0].details
                      ?.itemCode
                  )
                }
              >
                Shop Now
              </button>
            )}
          </div>
          <img
            className="full-banner-image"
            src={componentDesignData?.subTemplate?.widget[0].details.imageUrl}
            alt="full-banner"
          />
        </div>
        <div
          className="half-width-banner-1 r-075"
          style={{
            backgroundImage: `linear-gradient(284.6deg, ${componentDesignData?.subTemplate?.widget[1].details.startGradient} 0%, ${componentDesignData?.subTemplate?.widget[1].details.endGradient} 100%)`,
          }}
          onClick={() => {
            width < 1000
              ? handleRedirect(
                  componentDesignData?.subTemplate?.widget[1].details
                    ?.redirectType,
                  componentDesignData?.subTemplate?.widget[1].details?.itemCode
                )
              : console.log("");
          }}
        >
          <div className="heading-button-wrapper">
            <h3
              className="half-banner-title"
              style={{
                color:
                  componentDesignData?.subTemplate?.widget[1].details.fontColor,
              }}
            >
              {componentDesignData?.subTemplate?.widget[1].details.title}
            </h3>
            {width >= 1000 && (
              <button
                className="half-banner-cta-btn cta_btn"
                style={{
                  background:
                    componentDesignData?.subTemplate?.widget[1].details
                      .buttonColor,
                  color:
                    componentDesignData?.subTemplate?.widget[1].details
                      .buttonFontColor,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleRedirect(
                    componentDesignData?.subTemplate?.widget[1].details
                      ?.redirectType,
                    componentDesignData?.subTemplate?.widget[1].details
                      ?.itemCode
                  )
                }
              >
                Shop Now
              </button>
            )}
          </div>
          <img
            className="banner-image"
            src={componentDesignData?.subTemplate?.widget[1].details.imageUrl}
            alt="half-banner"
          />
        </div>
        <div
          className="half-width-banner-2 r-075"
          style={{
            background: `linear-gradient(284.6deg, ${componentDesignData?.subTemplate?.widget[2].details.startGradient} 0%, ${componentDesignData?.subTemplate?.widget[2].details.endGradient} 100%)`,
          }}
          onClick={() => {
            width < 1000
              ? handleRedirect(
                  componentDesignData?.subTemplate?.widget[2].details
                    ?.redirectType,
                  componentDesignData?.subTemplate?.widget[2].details?.itemCode
                )
              : console.log("");
          }}
        >
          <div className="heading-button-wrapper">
            <h3
              className="half-banner-title"
              style={{
                color:
                  componentDesignData?.subTemplate?.widget[2].details.fontColor,
              }}
            >
              {componentDesignData?.subTemplate?.widget[2].details.title}
            </h3>
            {width > 1000 && (
              <button
                className="half-banner-cta-btn cta_btn"
                style={{
                  background:
                    componentDesignData?.subTemplate?.widget[2].details
                      .buttonColor,
                  color:
                    componentDesignData?.subTemplate?.widget[2].details
                      .buttonFontColor,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleRedirect(
                    componentDesignData?.subTemplate?.widget[2].details
                      ?.redirectType,
                    componentDesignData?.subTemplate?.widget[2].details
                      ?.itemCode
                  )
                }
              >
                Shop Now
              </button>
            )}
          </div>
          <img
            className="banner-image"
            src={componentDesignData?.subTemplate?.widget[2].details.imageUrl}
            alt="half-banner"
          />
        </div>
      </div>
    </div>
  );
};

export default ThreeGridBanner;
