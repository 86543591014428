import React, { useEffect, useState, useContext } from "react";
import { MdDeleteForever } from "react-icons/md";
import CartButton from "../CartButton/CartButton";
import box from "../../assets/images/placeholder.svg";
import { IMAGE_BASE_URL } from "../../api/config";
import { deleteFromCart, addToCart, updateToCart } from "../../api";
import { AppContext } from "../../context/AppContext";
import "./SidebarCartItem.css";
import { useHistory } from "react-router";
import TagManager from "react-gtm-module";

function SidebarCartItem(props) {
  const history = useHistory();
  const {
    setShowToast,
    setToastData,
    currentStore,
    addToCartContext,
    updateCartContext,
    removeFromCartContext,
    cartId
  } = useContext(AppContext);
  const [cartValue, setCartValue] = useState(props?.cartData?.qty);
  const [updatedValue, setUpdatedValue] = useState(props?.cartData?.qty);
  const [isCartLoading, setIsCartLoading] = useState(true);
  const [hasClicked, setHasClicked] = useState(0);

  function handleCart(methodToDo) {
    setIsCartLoading(true);
    const addToCartData = {
      cartItem: {
        quote_id: cartId,
        sku: props?.cartData?.sku,
        qty: cartValue
      }
    };
    switch (methodToDo) {
      case "addToCart":
        addToCart(currentStore?.store_code, addToCartData)
          .then(res => {
            cartDataLogger("addToCart", res);
            addToCartContext(res?.item_id, props?.cartData, res?.qty);
            setShowToast(true);
            setToastData({
              type: "success",
              text: "Product Added"
            });
            setUpdatedValue(cartValue);
          })
          .catch(e => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          })
          .finally(() => {
            setIsCartLoading(false);
          });
        break;
      case "updateToCart":
        updateToCart(
          currentStore?.store_code,
          props?.cartData?.item_id,
          addToCartData
        )
          .then(res => {
            cartDataLogger("addToCart", res);
            setUpdatedValue(cartValue);
            updateCartContext(res?.item_id, res?.qty);
            setShowToast(true);
            setToastData({
              type: "success",
              text: "Product Updated"
            });
          })
          .catch(e => {
            setCartValue(oldCartValue => oldCartValue - 1);
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          })
          .finally(() => {
            setIsCartLoading(false);
          });
        break;
      case "deleteFromCart":
        deleteFromCart(currentStore?.store_code, props?.cartData?.item_id)
          .then(res => {
            if (res) {
              cartDataLogger("removeFromCart", props?.cartData);
              removeFromCartContext(props?.cartData?.item_id);
              setShowToast(true);
              setToastData({
                type: "success",
                text: "Product Removed"
              });
            }
          })
          .catch(e => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message
            });
          })
          .finally(() => {
            setIsCartLoading(false);
          });
        break;
    }
  }

  const cartDataLogger = (eventName, data) => {
    process?.env?.REACT_APP_ENV === "production" &&
      TagManager.dataLayer({
        dataLayerName: "dataLayer",
        dataLayer: {
          ecommerce: null
        }
      });
    if (eventName === "addToCart") {
      process?.env?.REACT_APP_ENV === "production" &&
        TagManager.dataLayer({
          dataLayerName: "dataLayer",
          dataLayer: {
            event: eventName,
            ecommerce: {
              currencyCode: "AED",
              add: {
                // 'add' actionFieldObject measures.
                products: [
                  {
                    //  adding a product to a shopping cart.
                    name: data?.name,
                    id: data?.sku,
                    price:
                      props?.cartData?.tax_included_special_price > 0
                        ? props?.cartData?.tax_included_special_price || 0
                        : props?.cartData?.tax_included_price,
                    brand: "",
                    category: "",
                    variant: "",
                    quantity: data?.qty
                  }
                ]
              }
            }
          }
        });
    } else {
      process?.env?.REACT_APP_ENV === "production" &&
        TagManager.dataLayer({
          dataLayerName: "dataLayer",
          dataLayer: {
            event: eventName,
            ecommerce: {
              currencyCode: "AED",
              remove: {
                // 'add' actionFieldObject measures.
                products: [
                  {
                    //  adding a product to a shopping cart.
                    name: data?.name,
                    id: data?.sku,
                    price:
                      props?.cartData?.tax_included_special_price > 0
                        ? props?.cartData?.tax_included_special_price || 0
                        : props?.cartData?.tax_included_price,
                    brand: "",
                    category: "",
                    variant: "",
                    quantity: data?.qty
                  }
                ]
              }
            }
          }
        });
    }
  };
  useEffect(() => {
    if (hasClicked === 0) return;
    if (cartValue == 0) return handleCart("deleteFromCart");
    if (cartValue > 0) return handleCart("updateToCart");
    if (cartValue > 0) return handleCart("addToCart");
  }, [hasClicked]);

  useEffect(() => {
    setCartValue(props?.cartData?.qty);
    setUpdatedValue(props?.cartData?.qty);
    setIsCartLoading(false);
  }, [props?.cartData?.qty]);

  function handleNoImage(el) {
    el.target.src = box;
  }

  const onCartItemClick = () => {
    process?.env?.REACT_APP_ENV === "production" &&
      TagManager.dataLayer({
        dataLayerName: "dataLayer",
        dataLayer: {
          ecommerce: null
        }
      });
    process?.env?.REACT_APP_ENV === "production" &&
      TagManager.dataLayer({
        dataLayerName: "dataLayer",
        dataLayer: {
          event: "productClick",
          ecommerce: {
            click: {
              actionField: { list: "sidecart" }, // Optional list property.
              products: [
                {
                  name: props?.cartData?.name, // Name or ID is required.
                  id: props?.cartData?.sku,
                  price:
                    props?.cartData?.tax_included_special_price > 0
                      ? props?.cartData?.tax_included_special_price || 0
                      : props?.cartData?.tax_included_price,
                  brand: "",
                  category: "",
                  variant: "",
                  position: 0
                }
              ]
            }
          }
        }
      });
    history.push(`/products/${props?.cartData?.sku}`);
    props.setSidebar(false);
  };

  return (
    <div className="d-flex justify-content-between p-2 pb-4 p-md-4 position-relative flex-wrap flex-md-nowrap">
      <div className="d-flex align-items-start flex-grow-1 w-100">
        <div className="position-relative">
          <img
            className="sidebar-item-img"
            onError={handleNoImage}
            onClick={onCartItemClick}
            src={
              props?.cartData?.image
                ? IMAGE_BASE_URL +
                  "/catalog/product" +
                  props?.cartData?.image.replace("/media" || "/product", "")
                : box
            }
            style={{ cursor: "pointer" }}
            alt="product"
          />
          <button
            className="p-0 sidebar-item-delete d-flex align-items-center justify-content-center click-anim"
            onClick={() => handleCart("deleteFromCart")}
          >
            <MdDeleteForever className="text-white" />
          </button>
        </div>
        <div>
          <p
            className="paragraph-info mb-1 ml-2 ml-md-0"
            style={{ cursor: "pointer" }}
            onClick={onCartItemClick}
          >
            {props?.cartData?.name}
          </p>
          {props?.cartData?.is_salable === 0 ||
          props?.cartData?.salable_qty[0]?.qty === 0 ? (
            <p className="no-stock-cart ml-2 ml-md-0">Out of Stock</p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="d-flex d-md-block text-center align-items-center justify-content-end flex-grow-1">
        <p className="mb-0 mb-md-3 mr-3 mr-md-0 green-label straight-text side-cart-price">{`AED ${parseFloat(
          props?.cartData?.tax_included_price
        ).toFixed(2)}`}</p>
        {props?.cartData?.is_salable === 0 ||
        props?.cartData?.salable_qty[0]?.qty === 0 ? (
          <></>
        ) : (
          <CartButton
            loaderColor="#00983d"
            bg="transparent"
            color="#454545"
            border="1px solid #f2f2f2"
            type="compact"
            updatedValue={updatedValue}
            hasClicked={hasClicked}
            setHasClicked={setHasClicked}
            isCartLoading={isCartLoading}
            cartValue={cartValue}
            setCartValue={setCartValue}
          />
        )}
      </div>
      {0 < props?.cartData?.salable_qty[0]?.qty &&
      props?.cartData?.salable_qty[0]?.qty <= cartValue ? (
        <div className="hurry-items-sidebar">
          Only {props?.cartData?.salable_qty[0]?.qty} left
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SidebarCartItem;
