import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";
import "../NoMarginBanner.css";

const NoMarginItem = ({ componentDesignData }) => {
  const { width } = useWindowSize();
  const { getRedirectionUrl } = useContext(AppContext);
  const history = useHistory();
  const handleRedirect = (redirectType, itemCode) => {
    const url = getRedirectionUrl(redirectType, itemCode);
    history.push(url);
  };
  return (
    <div
      className="no-margin-banner"
      style={{
        backgroundImage: `linear-gradient(${componentDesignData?.subTemplate?.widget[0].details.startGradient} 100%, ${componentDesignData?.subTemplate?.widget[0].details.endGradient} 100%)`,
      }}
    >
      <div className="">
        <div className="d-flex align-items-center justify-content-between no-margin-banner_content">
          <div className="d-flex flex-column align-items-start justify-content-around">
            <h2
              className="no-margin-banner-title"
              style={{
                color:
                  componentDesignData?.subTemplate?.widget[0].details.fontColor,
              }}
            >
              {componentDesignData?.subTemplate?.widget[0].details.title}
            </h2>
            {width > 768 && (
              <div className="no-margin-discount-label">
                {
                  componentDesignData?.subTemplate?.widget[0].details
                    .discountLabel
                }
              </div>
            )}
            <button
              className="no-margin-banner-cta_btn"
              style={{
                background:
                  componentDesignData?.subTemplate?.widget[0].details
                    .buttonColor,
              }}
              onClick={() =>
                handleRedirect(
                  componentDesignData?.subTemplate?.widget[0].details
                    .redirectType,
                  componentDesignData?.subTemplate?.widget[0].details.itemCode
                )
              }
            >
              {componentDesignData?.subTemplate?.widget[0].details.buttonName}
            </button>
          </div>

          <img
            src={componentDesignData?.subTemplate?.widget[0].details.imageUrl}
            alt=""
            className="NoMarginBanner__image"
          />
        </div>
      </div>
    </div>
  );
};

export default NoMarginItem;
