import React, { useState, useEffect, useContext, useRef } from "react";
import "./ProductListing.css";
import ProductGrid from "../../components/ProductGrid";
import MainLoader from "../../components/MainLoader";
import { getCategoryItems, getCategoryName } from "../../api";
import _ from "underscore";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { AppContext } from "../../context/AppContext";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import CategorySideMenu from "../../components/CategorySideMenu";
import {
  Accordion,
  AccordionContext,
  Row,
  useAccordionToggle
} from "react-bootstrap";

import useWindowSize from "../../hooks/useWindowSize";
import TagManager from "react-gtm-module";

const ProductListing = props => {
  const { currentStore, setBreadcrumbs, categories } = useContext(AppContext);
  const { width } = useWindowSize();
  const [endMessage, setEndMessage] = useState();
  const catListHead = useRef(null);
  const [catHeading, setCatHeading] = useState();
  const [listLoading, setListLoading] = useState(false);
  const [productData, setProductData] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState();
  const catId = props.match.params.catId;
  const [catArray, setCatArray] = useState([]);
  const filterCatId = id => {
    let tempArray = [];
    categories?.children_data?.map(i1 => {
      tempArray = [
        {
          id: i1?.id,
          path: "/categories/" + i1?.id,
          title: i1?.name
        }
      ];
      if (i1?.id === id) {
        setCatArray(tempArray);
      } else {
        i1?.children_data?.map(i2 => {
          tempArray = [
            { id: i1?.id, path: "/categories/" + i1?.id, title: i1?.name },
            { id: i2?.id, path: "/categories/" + i2?.id, title: i2?.name }
          ];
          if (i2?.id === id) {
            setCatArray(tempArray);
          } else {
            i2?.children_data?.map(i3 => {
              tempArray = [
                { id: i1?.id, path: "/categories/" + i1?.id, title: i1?.name },
                { id: i2?.id, path: "/categories/" + i2?.id, title: i2?.name },
                { id: i3?.id, path: "/categories/" + i3?.id, title: i3?.name }
              ];
              if (i3?.id === id) {
                setCatArray(tempArray);
              }
            });
          }
        });
      }
    });
  };
  function getPos(el) {
    // yay readability
    for (
      var lx = 0, ly = 0;
      el != null;
      lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent
    );
    return { x: lx, y: ly };
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    filterCatId(catId);
  }, [catId]);

  const fetchMoreListItems = () => {
    getCategoryItems(catId, 20, page, currentStore?.store_id)
      .then(res => {
        if (res?.length < 20) {
          setHasMore(false);
          setEndMessage(true);
        }
        setProductData([...productData, ...res]);
        setPage(page + 1);
      })
      .catch(e => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    setHasMore(true);
    setPage(1);
    setEndMessage(false);
    if (currentStore) {
      setListLoading(true);
      getCategoryName(catId)
        .then(nameData => {
          setCatHeading(nameData?.name);
          getCategoryItems(catId, 20, 1, currentStore?.store_id)
            .then(res => {
              if (res?.length === 0) {
                setHasMore(false);
                setEndMessage(false);
              }
              process?.env?.REACT_APP_ENV === "production" &&
                TagManager.dataLayer({
                  dataLayerName: "dataLayer",
                  dataLayer: {
                    ecommerce: null
                  }
                });
              process?.env?.REACT_APP_ENV === "production" &&
                TagManager.dataLayer({
                  dataLayerName: "dataLayer",
                  dataLayer: {
                    event: "gtm.dom",
                    ecommerce: {
                      currencyCode: "AED",
                      impressions: res
                    }
                  }
                });
              setProductData(res);
              setPage(page + 1);
            })
            .catch(e => {
              setHasMore(false);
            })
            .finally(() => {
              setListLoading(false);
            });
        })
        .catch(e => {
          setListLoading(false);
          setHasMore(false);
        });
    }
  }, [catId, currentStore]);

  useDocumentTitle(
    catHeading
      ? catHeading + " - Nesto Online Shopping"
      : "Category - Nesto Online Shopping"
  );
  useEffect(() => {
    props?.match?.path !== "/categories/:catId"
      ? setBreadcrumbs([{ title: "Home", path: "/" }, { title: catHeading }])
      : setBreadcrumbs([{ title: "Home", path: "/" }, ...catArray]);
  }, [catArray, catHeading]);
  return (
    <div className="container">
      {catArray ? (
        <div className="row">
          {props?.match?.path === "/categories/:catId" && width > 768 && (
            <div className="col-lg-3 col-md-4 col-sm-5 prod-list-menu my-3">
              {/* <Accordion defaultActiveKey="0"> */}
              {/* <Accordion.Toggle as={Row} variant="text" eventKey="0"> */}
              <div className="cat-side-menu-head">Categories</div>
              {/* </Accordion.Toggle> */}
              {/* <Accordion.Collapse eventKey="0"> */}

              <CategorySideMenu catId={catId} />
              {/* </Accordion.Collapse> */}
              {/* <Accordion.Toggle as={Row} variant="text" eventKey="1"> */}
              {/* <div className="cat-side-menu-head c-pointer">Filters</div> */}
              {/* </Accordion.Toggle> */}
              {/* <Accordion.Collapse eventKey="1"> */}
              {/* <div>Filter</div> */}
              {/* </Accordion.Collapse> */}
              {/* </Accordion> */}
            </div>
          )}
          <div
            ref={catListHead}
            className={
              (props?.match?.path === "/categories/:catId" &&
                "col-lg-9 col-md-8") + " col-12 prod-list-list my-3"
            }
          >
            {listLoading ? (
              <MainLoader innerClassName="product-list-loader-animation" />
            ) : (
              <div>
                <h4>{catHeading}</h4>
                <InfiniteScroll
                  dataLength={20 * page - 8} //This is important field to render the next data
                  next={fetchMoreListItems}
                  hasMore={hasMore}
                  loader={
                    <div className="d-flex w-100 justify-content-center align-items-center p-2">
                      <Loader
                        type="ThreeDots"
                        color={props?.loaderColor ?? "mediumseagreen"}
                        height={25}
                        width={50}
                      />
                    </div>
                  }
                  endMessage={
                    endMessage && (
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    )
                  }
                >
                  <ProductGrid
                    data={productData}
                    from={catHeading}
                    type={
                      props?.match?.path !== "/categories/:catId"
                        ? "nomenu"
                        : ""
                    }
                  />
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductListing;
