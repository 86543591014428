import React, { useContext, useEffect, useState } from 'react';
import { FaRegCalendarAlt, FaMoneyBillAlt, FaRegClock } from 'react-icons/fa';
import box from '../../assets/images/placeholder.svg';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { GrLocation } from 'react-icons/gr';
import { Dropdown, Popover, OverlayTrigger } from 'react-bootstrap';
import './Checkout.css';
import { AppContext } from '../../context/AppContext';
import { IMAGE_BASE_URL } from '../../api/config';
import {
	addShipping,
	getTimeSlots,
	cardPayment,
	getUser,
	checkLocation,
	createCart,
	createOrder,
	getOrder,
} from '../../api';
import { useHistory } from 'react-router';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import AddressField from '../../components/AddressField';
import _ from 'underscore';
import { AiFillThunderbolt, AiOutlineClockCircle } from 'react-icons/ai';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import TagManager from 'react-gtm-module';
import { minimumPrice } from '../../constants/storeMinimum';

function Checkout() {
	const {
		updateUser,
		profile,
		setShowToast,
		setToastData,
		currentStore,
		cartData,
		cartId,
		updateActivePaymentPayload,
		setBreadcrumbs,
		setCartUpdated,
		initializeCart,
		addressLocation,
		store,
	} = useContext(AppContext);
	const history = useHistory();
	const [deliveryTime, setDeliveryTime] = useState();
	const [deliveryDate, setDeliveryDate] = useState({
		date: moment().format('yyyy-MM-DD'),
		name: 'Today',
	});
	const [shippingAddress, setShippingAddress] = useState();
	const [addresses, setAddresses] = useState([]);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [totals, setTotals] = useState({});
	const [paymentMethod, setPaymentMethod] = useState();
	const [checkValidation, setCheckValidation] = useState(false);
	const [isToggle, setIsToggle] = useState(true);
	const [wasOrderPlaced, setWasOrderPlace] = useState(false);
	const [priceLoading, setPriceLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [deliveryNote, setDeliveryNote] = useState('');
	const [detailsAvailable, setDetailsAvailable] = useState(false);
	const [showAddAddress, setShowAddAddress] = useState(false);
	const [deliveryCords, setDeliveryCords] = useState({});
	const [checkoutSlots, setcheckoutSlots] = useState([]);
	const [timeSlotID, setTimeSlotID] = useState(0);

	useEffect(() => {
		setBreadcrumbs([
			{ title: 'Home', path: '/' },
			{ title: 'Cart', path: '/cart' },
			{ title: 'Checkout' },
		]);
		if (paymentMethods?.length < 1) {
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
		getUser().then((userres) => {
			updateUser(userres);
		});
		setCartUpdated((old) => !old);
		setWasOrderPlace(false);
	}, []);
	const popover = (
		<Popover id='popover-basic'>
			<Popover.Title as='h3' className='text-center'>
				Substitutions
			</Popover.Title>
			<Popover.Content>
				When enabled, if you miss the substitution suggestion time limit of 10
				minutes, Nesto will automatically allocate the next best substitute for
				any out-of-stock items.
			</Popover.Content>
		</Popover>
	);

	useEffect(() => {
		setAddresses(profile?.addresses);
	}, [profile?.addresses, currentStore]);

	useEffect(() => {
		currentStore?.store_id &&
			getTimeSlots(
				currentStore?.store_code,
				moment(deliveryDate.date).format('yyyy-MM-DD'),
				currentStore?.store_id
			)
				.then((res) => {
					setTimeSlotID(res[0]);
					let timeFilter;
					if (deliveryDate.name === 'Today') {
						timeFilter = res[9].default.time_limits.filter(
							(i) =>
								i.cut_off_time.replace(':', '') >
								parseInt(moment.utc().format('HHmm')) + 400
						);
						let expressSlots = timeFilter.filter(
							(x) =>
								x.slot_type === 'express' &&
								x.start_time.replace(':', '') <=
									parseInt(moment.utc().format('HHmm')) + 400
						);
						timeFilter = timeFilter.filter((x) => x.slot_type === 'scheduled');
						timeFilter = [...timeFilter, ...expressSlots];
					} else {
						timeFilter = res[9].default.time_limits.filter(
							(x) => x.slot_type === 'scheduled'
						);
					}
					timeFilter = _.sortBy(timeFilter, 'from');
					setcheckoutSlots(timeFilter);

					setDeliveryTime({
						from: timeFilter[0]?.from || null,
						to: timeFilter[0]?.to || null,
						slot_id: timeFilter[0]?.position || null,
					});
				})
				.catch((e) => console.log(e.message));
	}, [deliveryDate.date]);

	const handleProceedToCheckOut = () => {
		if (cartData?.filter((x) => x.is_salable !== 1).length > 0) {
			setToastData({
				type: 'error',
				text: 'Out of stock item(s) in your cart',
			});
			setShowToast(true);
			return 0;
		}

		if (!deliveryTime?.slot_id || !shippingAddress) {
			if (!deliveryTime?.slot_id) {
				setShowToast(true);
				setToastData({
					type: 'error',
					text: 'Select a Delivery Slot',
				});
			}
			if (!shippingAddress) {
				setShowToast(true);
				setToastData({
					type: 'error',
					text: 'Select a Delivery Address',
				});
			}
		} else {
			setPriceLoading(true);
			addShipping(currentStore?.store_code, {
				addressInformation: {
					shipping_address: shippingAddress,
					billing_address: shippingAddress,
					shipping_carrier_code: 'tablerate',
					shipping_method_code: 'bestway',
				},
			})
				.then((res) => {
					setPaymentMethods(res.payment_methods);
					setTotals(res.totals);
					if (res.payment_methods.length > 0) {
						setDetailsAvailable(true);
						window.scrollTo({
							top: document.getElementById('section_2').offsetTop - 100,
							behavior: 'smooth',
						});
					}
				})
				.catch((e) => console.log(e.message))
				.finally(() => setPriceLoading(false));
		}
	};

	useEffect(() => {
		if (!wasOrderPlaced && detailsAvailable) {
			handleProceedToCheckOut();
		}

		if (!wasOrderPlaced && cartData?.length < 1) {
			history.push('/cart');
		}
	}, [cartData]);

	const handlePlaceOrder = () => {
		if (
			totals?.base_subtotal + totals?.base_tax_amount <
			(process?.env?.REACT_APP_ENV === 'production'
				? minimumPrice[store?.sap_website_id]
				: 20)
		) {
			setShowToast(true);
			setToastData({
				type: 'error',
				text: `Minimum Cart Value excluding delivery charge should be ${
					process?.env?.REACT_APP_ENV === 'production'
						? minimumPrice[store?.sap_website_id]
						: 20
				} AED`,
			});
			return 0;
		}

		checkLocation({
			point: {
				lat: deliveryCords?.lat || addressLocation?.lat,
				long: deliveryCords?.long || addressLocation?.long,
			},
		}).then((res) => {
			if (!res?.data?.isPointInPolygon) {
				setShowToast(true);
				setToastData({
					type: 'error',
					text: 'Selected Location is Not Deliverable',
				});
				window.scrollTo({
					top: document.getElementById('section_1').offsetTop - 500,
					behavior: 'smooth',
				});
			} else {
				if (paymentMethod) {
					process?.env?.REACT_APP_ENV === 'production' &&
						TagManager.dataLayer({
							dataLayerName: 'dataLayer',
							dataLayer: {
								ecommerce: null,
							},
						});

					process?.env?.REACT_APP_ENV === 'production' &&
						TagManager.dataLayer({
							dataLayerName: 'dataLayer',
							dataLayer: {
								event: 'checkoutOption',
								ecommerce: {
									checkout_option: {
										actionField: { step: 3, option: paymentMethod },
									},
								},
							},
						});
					setIsLoading(true);
					let prods = [];
					cartData?.map((i) => {
						prods.push({
							name: i?.name,
							id: i?.sku,
							price: i?.tax_included_price,
							brand: '',
							category: '',
							variant: '',
							quantity: i?.qty,
						});
					});
					process?.env?.REACT_APP_ENV === 'production' &&
						TagManager.dataLayer({
							dataLayerName: 'dataLayer',
							dataLayer: {
								ecommerce: null,
							},
						});

					process?.env?.REACT_APP_ENV === 'production' &&
						TagManager.dataLayer({
							dataLayerName: 'dataLayer',
							dataLayer: {
								event: 'checkout',
								ecommerce: {
									checkout: {
										actionField: {
											list: 'checkout',
											step: 3,
											option: paymentMethod,
										},
										products: prods,
									},
								},
							},
						});
					if (paymentMethod === 'ngeniusonline') {
						updateActivePaymentPayload(
							{
								quote_id: cartId,
								custom_data: {
									delivery_date: moment(deliveryDate.date).format('YYYY-MM-DD'),
									from: deliveryTime?.from,
									to: deliveryTime?.to,
									lat: deliveryCords?.lat || addressLocation?.lat,
									long: deliveryCords?.long || addressLocation?.long,
									timeslot_id: timeSlotID,
									store_id: currentStore?.store_id,
									is_substitution: isToggle,
									delivery_notes:
										deliveryNote == ''
											? 'Placed on Website'
											: deliveryNote + '-Placed on Website',
								},
							},
							totals
						);
						const redirectUrl =
							process?.env?.REACT_APP_ENV === 'production'
								? `https://gateway.nesto.shop/auth/production_payment/status?env=production&payment=`
								: `https://gateway.nesto.shop/auth/payment/status?env=staging&payment=`;
						const payload = {
							env: process?.env?.REACT_APP_ENV,
							quot_id: cartId,
							currencyCode: totals.base_currency_code,
							value: totals.base_grand_total,
							redirectUrl:
								redirectUrl +
								encodeURIComponent(
									window.location.href.replace('/checkout', '/order-status')
								),
							sub_total: totals.base_subtotal,
						};
						cardPayment(payload)
							.then((res) => {
								setIsLoading(false);
								if (res?.error) {
									setShowToast(true);
									setToastData({
										type: 'error',
										text: res?.msg,
									});
								} else {
									if (res?.reference_id) {
										window.location.href = res.payment_link;
									} else {
										setShowToast(true);
										setToastData({
											type: 'error',
											text: 'Something went wrong!',
										});
									}
								}
							})
							.catch(() => {
								setIsLoading(false);
								setShowToast(true);
								setToastData({
									type: 'error',
									text: 'Something went wrong!',
								});
							})
							.finally(() => setIsLoading(false));
					} else {
						if (deliveryTime.slot_id) {
							createOrder(currentStore?.store_code, {
								quote_id: cartId,
								custom_data: {
									delivery_date: moment(deliveryDate.date).format('YYYY-MM-DD'),
									from: deliveryTime?.from,
									to: deliveryTime?.to,
									lat: deliveryCords?.lat || addressLocation?.lat,
									long: deliveryCords?.long || addressLocation?.long,
									timeslot_id: timeSlotID,
									store_id: currentStore?.store_id,
									is_substitution: isToggle,
									delivery_notes:
										deliveryNote == ''
											? 'Placed on Website'
											: deliveryNote + '-Placed on Website',
								},
								reference_id: '',
								payment_method: 'cashondelivery',
							})
								.then((res) => {
									if (res?.error) {
										setShowToast(true);
										setToastData({
											type: 'error',
											text: res?.msg,
										});
										return 0;
									}
									process?.env?.REACT_APP_ENV === 'production' &&
										TagManager.dataLayer({
											dataLayerName: 'dataLayer',
											dataLayer: {
												ecommerce: null,
											},
										});
									let orderProds = [];
									let tempCartData = [];
									getOrder(res?.orderid).then((orderRes) => {
										tempCartData = orderRes?.items;
									});
									tempCartData?.map((i) => {
										orderProds.push({
											name: i?.name,
											id: i?.sku,
											price: i?.tax_included_price,
											brand: '',
											category: '',
											variant: '',
											quantity: i?.qty,
										});
									});
									process?.env?.REACT_APP_ENV === 'production' &&
										TagManager.dataLayer({
											dataLayerName: 'dataLayer',
											dataLayer: {
												event: 'purchaseCompleted',
												ecommerce: {
													purchase: {
														actionField: {
															id: res?.orderid, // Transaction ID. Required for purchases and refunds.
															affiliation: 'Online Store',
															revenue: totals?.base_grand_total, // Total transaction value (incl. tax and shipping)
															tax:
																totals?.base_grand_total -
																totals?.base_subtotal,
															shipping: totals?.shipping_incl_tax,
															coupon: '',
														},
														products: orderProds,
													},
												},
											},
										});
									setWasOrderPlace(true);
									createCart(currentStore?.store_code).then((cartres) => {
										initializeCart({ id: cartres, items: [] });
										history.push(`/order-status/order-placed/${res?.orderid}`);
									});
								})
								.catch((e) => history.push('/order-status/order-failed'))
								.finally(() => setIsLoading(false));
						}
					}
				} else {
					setShowToast(true);
					setToastData({
						type: 'info',
						text: 'Select a payment method',
					});
				}
			}
		});
	};

	function handleToggle() {
		setIsToggle(!isToggle);
	}

	const handleOnAddressSelect = (addressItem) => {
		setPriceLoading(true);
		checkLocation({
			point: {
				lat: addressItem?.custom_attributes?.filter(
					(i) => i.attribute_code === 'latitude'
				)[0]?.value,
				long: addressItem?.custom_attributes?.filter(
					(i) => i.attribute_code === 'longitude'
				)[0]?.value,
			},
		})
			.then((locationRes) => {
				if (locationRes?.data?.stores[0]?.store_id !== currentStore?.store_id) {
					setShowToast(true);
					setToastData({
						type: 'error',
						text: 'Cannot deliver to the selected address from current store',
					});
					setCheckValidation(false);
					return;
				}

				setDeliveryCords({
					lat: addressItem?.custom_attributes?.filter(
						(i) => i.attribute_code === 'latitude'
					)[0]?.value,
					long: addressItem?.custom_attributes?.filter(
						(i) => i.attribute_code === 'longitude'
					)[0]?.value,
					name: addressItem?.custom_attributes?.filter(
						(i) => i.attribute_code === 'location'
					)[0]?.value,
				});
				setShippingAddress({
					firstname: addressItem?.firstname,
					lastname: addressItem?.lastname,
					telephone: addressItem?.telephone,
					email: profile?.email,
					country_id: addressItem?.country_id,
					postcode: addressItem?.postcode || '',
					region_id: 43,
					region: addressItem?.region?.region,
					city: addressItem?.city,
					region_code: addressItem?.region?.region_code,
					street: addressItem?.street,
					custom_attributes: addressItem?.custom_attributes,
				});
				setCheckValidation(true);
			})
			.finally(() => setPriceLoading(false));
	};

	useDocumentTitle('Checkout - Nesto Online Shopping');

	return (
		<div className='checkout mx-lg-5'>
			<div className='offset-lg-2 col-lg-8 offset-0 col-12' id='section_1'>
				{!showAddAddress ? (
					<div className='border-line-all p-4 container-radius'>
						<p className='text-14-600'>Delivery Time</p>
						<div className='row my-4'>
							<div className='col-md-6 my-1 checkout-dropdown'>
								<Dropdown>
									<Dropdown.Toggle className='py-3 d-flex align-items-center'>
										<span className='react-icons'>
											<FaRegCalendarAlt />
										</span>
										<span className='ml-2'>{deliveryDate.name}</span>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item
											onSelect={() => {
												setDeliveryDate({
													date: moment(),
													name: 'Today',
												});
											}}
										>
											Today
										</Dropdown.Item>
										<Dropdown.Item
											onSelect={() => {
												setDeliveryDate({
													date: moment().add(1, 'days'),
													name: 'Tomorrow',
												});
											}}
										>
											Tomorrow
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className='col-md-6 my-1 checkout-dropdown'>
								<Dropdown>
									<Dropdown.Toggle className='py-3 d-flex align-items-center'>
										<span className='react-icons'>
											{deliveryTime?.slot_type === 'express' ? (
												<AiFillThunderbolt />
											) : (
												<AiOutlineClockCircle />
											)}
										</span>
										<span className='ml-2'>
											{deliveryTime?.from && deliveryTime?.to
												? moment(deliveryTime.from, ['H:mm']).format(
														'hh:mm a'
												  ) +
												  ' - ' +
												  moment(deliveryTime.to, ['H:mm']).format('hh:mm a')
												: 'Select a timeslot for delivery'}
										</span>
									</Dropdown.Toggle>

									<Dropdown.Menu className='time-slot-dropdown'>
										{checkoutSlots?.length > 0 &&
											checkoutSlots?.map((data, index) => {
												return (
													<Dropdown.Item
														key={index}
														onSelect={() => {
															setDeliveryTime({
																from: data.from,
																to: data.to,
																slot_id: data.position,
																slot_type: data.slot_type,
															});
														}}
													>
														{data.slot_type === 'express' ? (
															<AiFillThunderbolt />
														) : (
															<AiOutlineClockCircle />
														)}
														{' ' +
															moment(data.from, ['H:mm']).format('hh:mm a') +
															' - ' +
															moment(data.to, ['H:mm']).format('hh:mm a')}
													</Dropdown.Item>
												);
											})}
										{checkoutSlots?.length === 0 ? (
											<div className='w-100 p-2'>No Timeslots Available</div>
										) : (
											''
										)}
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div className='d-flex justify-content-between'>
							<p className='text-14-600'>Delivery Address</p>
							<p
								className='m-0 text-13 font-weight-bold green-color c-pointer'
								onClick={() => {
									setCheckValidation(false);
									setShowAddAddress(true);
									setShippingAddress({
										firstname: '',
										lastname: '',
										telephone: '',
										email: '',
										country_id: '',
										postcode: '',
										city: '',
										street: [''],
									});
								}}
							>
								Add New Address
							</p>
						</div>
						<div className='checkout-dropdown'>
							<Dropdown>
								<Dropdown.Toggle className='py-3 d-flex align-items-center'>
									<span className='react-icons'>
										<GrLocation />
									</span>
									<span className='ml-2 overflow-hidden'>
										{shippingAddress?.firstname !== '' &&
										shippingAddress?.city !== '' &&
										checkValidation
											? `${shippingAddress?.firstname}, ${shippingAddress?.street}, ${shippingAddress?.city}`
											: 'Please Select an Address'}
									</span>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{addresses?.length > 0 ? (
										addresses?.map((addressItem, index) => {
											return (
												<React.Fragment key={index}>
													<Dropdown.Item
														onSelect={() => {
															handleOnAddressSelect(addressItem);
														}}
													>
														{`${addressItem?.firstname} ${
															addressItem?.lastname !== addressItem?.firstname
																? addressItem?.lastname
																: ''
														}`}
														<br />
														{` ${addressItem?.telephone}`}
														<br />
														{`${addressItem?.street}`}
														<br />
														{`${addressItem?.city}`}
														{addressItem?.postcode &&
															` - ${addressItem?.postcode}`}
													</Dropdown.Item>
												</React.Fragment>
											);
										})
									) : (
										<div className='w-100 p-2'>
											0 Deliverable Address Found for the Current Store
										</div>
									)}
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<button
							className='btn btn-success click-anim w-100 mt-3 text-14 font-weight-normal p-3'
							// onClick={handleSaveAddress}
							onClick={() => {
								process?.env?.REACT_APP_ENV === 'production' &&
									TagManager.dataLayer({
										dataLayerName: 'dataLayer',
										dataLayer: {
											ecommerce: null,
										},
									});
								let prods = [];
								cartData?.map((i) => {
									prods.push({
										name: i?.name,
										id: i?.sku,
										price: i?.tax_included_price,
										brand: '',
										category: '',
										variant: '',
										quantity: i?.qty,
									});
								});
								process?.env?.REACT_APP_ENV === 'production' &&
									TagManager.dataLayer({
										dataLayerName: 'dataLayer',
										dataLayer: {
											event: 'checkout',
											ecommerce: {
												checkout: {
													actionField: { list: 'checkout', step: 2 },
													products: prods,
												},
											},
										},
									});
								handleProceedToCheckOut();
							}}
							disabled={!(deliveryTime?.slot_id && shippingAddress)}
						>
							{priceLoading ? (
								<Loader
									type='TailSpin'
									color={'white'}
									height={20}
									width={20}
								/>
							) : (
								'Proceed'
							)}
						</button>
					</div>
				) : (
					<AddressField
						setDeliveryCords={setDeliveryCords}
						setAddress={setShippingAddress}
						address={shippingAddress}
						setShowAddAddress={setShowAddAddress}
						addresses={profile?.addresses}
						setAddresses={setAddresses}
						checkValidation={checkValidation}
						setCheckValidation={setCheckValidation}
						edit={false}
						addressTo={'checkout'}
					/>
				)}
			</div>
			<div className='col-12 my-5' id='section_2'>
				<div className='row'>
					{detailsAvailable && (
						<>
							<div className='col-md-5 offset-md-1 my-2'>
								<div className='border-line-all p-4 container-radius'>
									<div className='d-flex justify-content-between align-items-center mb-3'>
										<p className='m-0 d-flex align-items-center'>
											<span className='mr-2'>Allow Substitutions</span>
											<OverlayTrigger
												trigger={['hover', 'focus']}
												placement='bottom'
												overlay={popover}
											>
												<BsFillInfoCircleFill />
											</OverlayTrigger>
										</p>

										<button
											className={
												'd-flex align-items-center toggle-switch' +
												(isToggle ? ' on' : '')
											}
											onClick={handleToggle}
										>
											<div className='toggle-indicator'></div>
										</button>
									</div>
									<textarea
										className='w-100 bg-gray border-0 p-2 container-radius address-text px-2'
										placeholder='Delivery Note'
										onChange={(e) => setDeliveryNote(e.target.value)}
									></textarea>
									<div className='my-3 d-flex justify-content-between'>
										<p className='m-0 text-14 fw-500'>Select Payment Method</p>
										{/* <p className="m-0 text-13 font-weight-bold green-color">
                        Add New Card
                      </p> */}
									</div>

									{paymentMethods?.map((item, index) => {
										if (item.code === 'ngeniusonline')
											return (
												<label
													key={index}
													htmlFor='payment'
													className='payment-label border-line-all p-3 container-radius d-flex justify-content-between align-items-center'
												>
													<div className='d-flex align-items-center'>
														<div className='border-line-all p-2 container-radius mastercard mr-2'></div>
														<span className='fw-500'>{item?.title}</span>
													</div>
													<input
														className='d-none pay-radio'
														id='payment'
														name='pay'
														type='radio'
														onChange={() => setPaymentMethod('ngeniusonline')}
													/>
													<div className='radio-button'></div>
													<div className='radio-border'></div>
												</label>
											);
										else if (item.code === 'cashondelivery')
											return (
												<label
													key={index}
													htmlFor='payment-3'
													className='payment-label border-line-all p-3 container-radius d-flex justify-content-between align-items-center'
												>
													<div className='d-flex align-items-center'>
														<div className='border-line-all p-2 container-radius mr-2'>
															<FaMoneyBillAlt className='cod-icon' />
														</div>
														<span className='fw-500'>{item?.title}</span>
													</div>
													<input
														className='d-none pay-radio'
														id='payment-3'
														name='pay'
														type='radio'
														onChange={() => setPaymentMethod('cashondelivery')}
													/>
													<div className='radio-button'></div>
													<div className='radio-border'></div>
												</label>
											);
									})}
									{/* <p className="text-14 font-weight-bold mt-4">
                      Apply Coupon
                    </p>
                    <div className="d-flex">
                      <input
                        className="p-3 border-line-all flex-grow-1 corner-left"
                        placeholder="Coupon Code"
                      />
                      <button className="icon-button p-2 corner-right">
                        <RiCoupon3Fill className="text-white fs-20" />
                      </button>
                    </div> */}
								</div>
							</div>
							<div className='col-md-5 my-2'>
								<div className='border-line-all container-radius'>
									<p className='m-0 faded-text fw-500 border-line p-4'>
										ITEMS IN CART
									</p>
									<div className='p-4'>
										{/* SINGLE ITEM */}
										{totals?.items?.map((item, index) => {
											return (
												<div
													key={index}
													className='d-flex justify-content-between bgc-grey p-3 container-radius'
												>
													<div className='d-flex'>
														<img
															className='mbm-multiply order-item-img'
															src={
																item?.image
																	? IMAGE_BASE_URL +
																	  '/catalog/product' +
																	  item?.image.replace(
																			'/media' || '/product',
																			''
																	  )
																	: box
															}
															alt='cart-item'
														/>
														<div className='ml-2 d-flex flex-column justify-content-around text-14'>
															<p className='m-0'>{item?.name}</p>
															<p className='m-0 fw-900'>{'x' + item?.qty}</p>
														</div>
													</div>
													<p className='m-0 green-label'>
														{'AED ' +
															parseFloat(item?.base_price_incl_tax).toFixed(2)}
													</p>
												</div>
											);
										})}
										{/* END OF SINGLE ITEM */}
									</div>
									<p className='m-0 faded-text fw-500 border-line p-4'>
										ORDER SUMMARY
									</p>
									<div className='p-4 border-line'>
										{totals?.total_segments?.map((item, index) => {
											if (item?.area !== 'footer') {
												return (
													<div
														key={index}
														className='d-flex justify-content-between mb-2 row'
														style={{ fontSize: '0.9em' }}
													>
														<p className='col-8 text-left'>{item?.title}</p>
														<p className='col-4 fw-500 text-right'>
															{totals?.base_currency_code +
																' ' +
																parseFloat(item?.value).toFixed(2)}
														</p>
													</div>
												);
											}
										})}
									</div>
									{totals?.total_segments?.map((item, index) => {
										if (item?.area === 'footer') {
											return (
												<div
													key={index}
													className='d-flex justify-content-between p-4 border-line'
												>
													<p className='m-0 fw-700 fs-20 mb-0 d-flex flex-column'>
														<span className='pb-0'>{item?.title}</span>
														<span className='fs-10 faded-text'>
															VAT Inclusive
														</span>
													</p>
													<p className='m-0 fw-700 fs-20'>
														{totals?.base_currency_code +
															' ' +
															parseFloat(item?.value).toFixed(2)}
													</p>
												</div>
											);
										}
									})}

									<div className='p-4'>
										<button
											className='primary-button p-3 w-100 rounded-button click-anim'
											onClick={handlePlaceOrder}
										>
											{isLoading ? (
												<Loader
													type='TailSpin'
													color={'white'}
													height={20}
													width={20}
												/>
											) : (
												'Place Order'
											)}
										</button>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Checkout;
