import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import emptyFavs from "../assets/images/favs-empty.svg";
import EmptyData from "../components/EmptyData";
import { AppContext } from "../context/AppContext";
import Cart from "../pages/Cart";
import CheckOrderStatus from "../pages/CheckOrderStatus";
import Checkout from "../pages/Checkout/Checkout";
import ErrorNotFound from "../pages/ErrorNotFound";
import Favourites from "../pages/Favourites";
import HomepageNew from "../pages/HomepageNew/HomepageNew";
import OrderDetails from "../pages/OrderDetails";
import OrderEdit from "../pages/OrderEdit";
import OrderStatusConfirm from "../pages/OrderStatusConfirm";
import ProductDetails from "../pages/ProductDetails";
import ProductListing from "../pages/ProductListing/ProductListing";
import Profile from "../pages/Profile";
import Search from "../pages/Search";
import TemplatePage from "../pages/TemplatePage/TemplatePage";

const Routes = () => {
  const { isLoggedIn } = useContext(AppContext);
  return (
    <Switch>
      <Route exact path="/" component={HomepageNew} />
      
      <Route exact path="/categories/:catId" component={ProductListing} />
      <Route exact path="/deals/:catId" component={ProductListing} />
      <Route exact path="/products/:sku" component={ProductDetails} />
      <Route exact path="/search" component={Search} />
      <Route exact path="/order-status/" component={CheckOrderStatus} />
      {isLoggedIn ? (
        <>
          <Route exact path="/account/:section" component={Profile} />
          <Route exact path="/orderdetails/:orderId" component={OrderDetails} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/editorder/:orderId" component={OrderEdit} />
          <Route exact path="/favourites" component={Favourites} />
          <Route
            exact
            path="/confirmorderaction/:orderId/:statusType"
            component={OrderStatusConfirm}
          />
          <Route
            exact
            path="/order-status/:statusType/:orderId"
            component={CheckOrderStatus}
          />
          <Route
            exact
            path="/order-status/:statusType"
            component={CheckOrderStatus}
          />

          <Route path="/:pageId" component={TemplatePage} />
          
        </>
      ) : (
        <Route
          exact
          path={[
            "/account/:section",
            "/orderdetails/:orderId",
            "/cart",
            "/checkout",
            "/editorder/:orderId",
            "/favourites",
            "/confirmorderaction/:orderId/:statusType",
            "/order-status/:statusType"
          ]}
        >
          <div className="p-5">
            <EmptyData
              picture={emptyFavs}
              heading={"Please Login to Continue"}
              description={
                "The page you are trying to access is only available for logged-in user."
              }
            />
          </div>
        </Route>
      )}
      <Route component={ErrorNotFound} />
      
    </Switch>
  );
};

export default Routes;
